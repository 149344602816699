.case-slider {
  background: white;
  color: black;
  overflow: hidden;

  &__nav {
    display: flex;
  }

  &__description-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__description {
    width: var(--grid-column3);
  }

  &__slider {
    &.swiper {
      margin: 0 calc(var(--grid-spacer) * -1);
      padding: 0 var(--grid-spacer);
    }
  }

  &__item {
    @for $i from 1 through 30 {
      &:nth-child(#{$i}) {
        .case-slider__picture {
          transition-delay: calc(((#{$i} - 1) * 0.1s));
        }
      }
    }
  }

  &__item-title {
    width: var(--grid-column4);
  }

  &__picture-wrapper {
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 38%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%);
    }
  }

  &__link {
    position: absolute;
    z-index: 15;
    right: 0;
    bottom: 0;
    padding: var(--slider-link-padding);
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    transition: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: var(--default-transition-function);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__progressbar-wrap {
    display: none;
  }

  &.need-animation {
    .case-slider__picture {
      transform: scale3d(1.1, 1.1, 1);
      opacity: 0;
    }
  }
}

@include respond-up("large") {
  .case-slider {
    &__inner {
      padding: 128px 0 135px;
    }

    &__nav {
      justify-content: space-between;
    }

    &__counter-container {
      flex: 0 1 100%;
    }

    &__description-container {
      flex: 0 0 var(--grid-column4);
    }

    &__wrapper {
      margin-top: 75px;
    }

    &__counter {
      display: flex;
      align-items: flex-end;
    }

    &__counter-slider {
      &.swiper {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &__counter-slider-list {
      &.swiper-wrapper {
        height: calc((100 / 1920) * 100vw);
      }
    }

    &__counter-total {
      margin-left: 22px;
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column8);
      }
    }

    &__picture-wrapper {
      width: var(--grid-column8);
      height: calc((659 / 1217) * var(--grid-column8));
    }

    &__description {
      width: calc(var(--grid-column3) - var(--grid-column) / 2);
    }
  }
}

@include respond-up("s-large") {
  .case-slider {
    --slider-link-padding: 30px;
  }
}

@include respond("s-large") {
  .case-slider {
    &__inner {
      padding: 106px 0 85px;
    }

    &__description {
      width: var(--grid-column4);
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column10);
      }
    }

    &__picture-wrapper {
      width: var(--grid-column10);
      height: calc((539 / 995) * var(--grid-column10));
    }

    &__item-title {
      width: var(--grid-column5);
    }
  }
}

@include respond-down("s-large") {
  .case-slider {
    &__counter-container {
      display: none;
    }

    &__description-container {
      width: 100%;
    }

    &__wrapper {
      margin-top: 55px;
    }
  }
}

@include respond-up("medium") {
  .case-slider {
    &__nav {
      align-items: flex-end;
    }

    &__progressbar-wrap {
      display: none;
    }

    &__item-title {
      margin-top: 15px;
    }
  }
}

@include respond("medium") {
  .case-slider {
    --slider-link-padding: 22px 20px;

    &__inner {
      padding: 53px 0 73px;
    }
  }
}

@include respond-down("medium") {
  .case-slider {
    &__item {
      &.swiper-slide {
        width: var(--grid-column5);
      }
    }

    &__picture-wrapper {
      width: var(--grid-column5);
      height: calc((348 / 642) * var(--grid-column5));
    }
  }
}

@include respond-down("small") {
  .case-slider {
    --slider-link-padding: 10px;

    &__inner {
      padding: 35px 0;
    }

    &__progressbar-wrap {
      position: relative;
      margin-top: 36px;

      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: -4px;
      }

      .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0;
      }

      .swiper-pagination-bullet {
        width: 16px;
        background: var(--primary-color-20);
        height: 2px;
        border-radius: 0;

        &-active {
          background: var(--primary-color);
        }
      }
    }

    &__description {
      width: var(--grid-column5);
    }

    &__arrows {
      display: none;
    }

    &__wrapper {
      margin-top: 35px;
    }

    &__link {
      .button__title {
        display: none;
      }
    }

    &__item-title {
      margin-top: 9px;
    }

    &__progressbar-wrap {
      position: relative;
      display: block;
      margin-top: 30px;
    }

    &__progressbar {
      --swiper-pagination-color: var(--primary-color);

      &.swiper-pagination-progressbar.swiper-pagination-horizontal {
        height: 2px;
        width: 78px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }

      &.swiper-pagination-progressbar {
        background-color: var(--primary-color-20);
      }
    }
  }
}
