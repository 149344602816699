.block-heading {
  display: flex;
  border-top: 1px solid #fff;

  &__title {
    color: #fff;
  }
}

@include respond-up('medium') {
  .block-heading {
    &__title {
      margin-top: 15px;
    }
  }
}

@include respond-down('small') {
  .block-heading {
    &__title {
      margin-top: 9px;
    }
  }
}