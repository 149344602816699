.drop-menu {
  position: absolute;
  z-index: 1998;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  padding-top: var(--header-height);

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 10;
    top: var(--header-height);
    left: var(--grid-spacer);
    right: var(--grid-spacer);
    border-bottom: 1px solid #fff;
    opacity: 0;
    transition: opacity .4s var(--default-transition-function);
  }

  &::after {
    top: unset;
    bottom: 100%;
    transition: {
      property: opacity, bottom;
      duration: .4s;
      timing-function: var(--default-transition-function);
    };
  }

  &._opened {
    pointer-events: auto;

    &::after {
      opacity: 1;
      bottom: 0;
    }

    &::before {
      opacity: 1;
    }

    .drop-menu {
      &__inner-background {
        transform: scale3d(1, 1, 1);
      }

      &__item {
        &_about {
          &:before,
          &:after {
            transform: scale3d(1, 1, 1);
          }
        }
      }

      &__item-value-wrap,
      &__modal {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition-delay: .1s;
      }
    }
  }

  &__item-title,
  &__presentation-link {
    color: #fff;
  }

  &__inner {
    transition: transform .4s var(--default-transition-function);
    position: relative;

    overflow: scroll;
    max-height: calc(100vh - var(--header-height));

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__inner-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--primary-color);
    transform-origin: top;
    transition: transform .4s var(--default-transition-function);
    transform: scale3d(1, 0, 1);
  }

  &__list {
    display: grid;
    grid-gap: 0;
  }

  &__item-value-wrap,
  &__modal {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
    transition: {
      property: transform, opacity;
      duration: .4s;
      timing-function: var(--default-transition-function);
    };
  }

  &__link {
    padding: 3px 0;
  }

  &__modal {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  &__modal-icon {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    svg {
      width: 100%;
      height: 100%;
      transition: transform .4s var(--default-transition-function);

      path {
        stroke: #fff;
      }
    }
  }
}

@include respond-up('s-large') {
  .drop-menu {
    &__list {
      grid-template-columns: var(--grid-column4) calc(var(--grid-column4) + var(--grid-gap) * 2) var(--grid-column4);
    }

    &__item {
      &_production {
        grid-column: 1/2;
        padding: 15px var(--grid-gap) 25px 0;
      }

      &_about {
        grid-column: 2/3;
        padding: 15px var(--grid-gap) 25px;

        &:after {
          content: '';
          position: absolute;
          background: #fff;
          top: calc(-1 * var(--header-height));
          width: 1px;
          height: calc(100% + var(--header-height));
          transform-origin: top;
          transition: transform .4s var(--default-transition-function);
          transform: scale3d(1, 0, 1);
        }

        &:after {
          right: 0;
        }
      }

      &_modal {
        grid-column: 3/4;
        margin-right: calc(var(--grid-spacer) * -1);
      }
    }

    &__presentation-wrap {
      display: flex;
      justify-content: flex-end;
      margin-top: 53px;
    }

    &__presentation-link {
      padding: 10px;
      margin: -10px;

      .text_title {
        text-transform: capitalize;

        &::before {
          transform: scaleX(0);
          transform-origin: center right;
        }
      }

      &:hover {
        .text_title {
          &::before {
            transform: scaleX(1);
            transform-origin: left center;
          }
        }
      }
    }

    &__modal {
      padding: 15px var(--grid-spacer) 15px 30px;

      &:hover {
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .drop-menu {
    &__link-list {
      margin: 82px 0 -3px;
    }

    &__modal-icon {
      width: 150px;
      height: 150px;
    }

    &__item {
      &_about {
        overflow: hidden;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          background: #fff;
          top: calc(-1 * var(--header-height));
          width: 1px;
          height: calc(100% + var(--header-height));
          transform-origin: top;
          transition: transform .4s var(--default-transition-function);
          transform: scale3d(1, 0, 1);
        }

        &:before {
          left: 0;
        }
      }
    }
  }
}

@include respond('medium') {
  .drop-menu {
    &._opened {
      .drop-menu {
        &__item {
          &_modal {
            border-top-color: #fff;
          }
        }
      }
    }

    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      &_production {
        grid-column: 1/2;
        padding: 15px var(--grid-gap) 98px 0;
      }

      &_about {
        grid-column: 2/3;
        padding: 15px 0 98px var(--grid-gap);
      }

      &_modal {
        grid-column: 1/3;
        border-top: 1px solid var(--t);
        transition: border-top-color .4s var(--default-transition-function);
        height: 350px;
      }
    }

    &__modal {
      padding: 15px 0;
    }
  }
}

@include respond-down('medium') {
  .drop-menu {
    height: calc(var(--vh, 1svh) * 100);

    &::after {
      display: none;
    }

    &__presentation-wrap {
      display: none;
    }

    &__list {
      padding-bottom: 100px;
    }

    &__item {
      &_modal {
        border-bottom: 1px solid var(--t);
        transition: all .3s var(--default-transition-function);
      }
    }

    &._opened {
      .drop-menu {
        &__item {
          &_modal {
            border-color: #fff;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .drop-menu {
    &._opened {
      .drop-menu {
        &__item {
          &:not(:first-child) {
            border-top-color: #fff;
          }
        }
      }
    }

    &__list {
      grid-template-columns: 1fr;
    }

    &__link-list {
      margin: 2px 0 -3px;
      padding-left: calc(var(--grid-column3) + var(--grid-gap));
    }

    &__item {
      &_production,
      &_about,
      &_modal {
        grid-column: span 1;
      }

      &:not(:first-child) {
        border-top: 1px solid var(--t);
        transition: border-top-color .4s var(--default-transition-function);
      }

      &_production,
      &_about {
        padding: 9px 0 45px;
      }

      &_modal {
        height: 203px;
      }
    }

    &__modal {
      padding: 9px 0;
    }

    &__modal-icon {
      width: 90px;
      height: 90px;
    }
  }
}