.text {
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  &_title {
    font-size: 15px;
    text-transform: uppercase;
  }

  &_main {
    font-size: 17px;
  }

  &_mini {
    font-size: 14px;
  }
}

@include respond('small') {
  .text {
    &_title {
      font-size: 13px;
    }

    &_main {
      font-size: 15px;
    }

    &_mini {
      font-size: 12px;
    }
  }
}
