.about {
  background: #fff;

  &__grid {
    display: grid;
    grid-column-gap: var(--grid-gap);
    grid-row-gap: 0;
  }

  &__title-container {
    grid-column: 1/7;
  }

  &__title,
  &__title-small {
    width: 100%;
  }

  &__picture-container {
    display: flex;
    position: relative;
    overflow: hidden;

    &.need-animation {
      .about__picture,
      .video-layout {
        transform: scale3d(1.1, 1.1, 1);
        opacity: 0;
      }
    }
  }

  &__picture-link {
    display: flex;
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &_inactive {
      pointer-events: none;
    }
  }

  &__presentation-container {
    display: flex;
    align-items: flex-end;
    grid-column: 1/4;
  }

  &__icon-container {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      border-left: 1px solid var(--primary-color);
      transition: height .8s var(--default-transition-function);
    }

    &.need-animation {
      &::before {
        height: 0;
      }

      .animation-mask__line span {
        transform: translate3d(0, 110%, 0);
      }
    }
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    transition: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: var(--default-transition-function);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__icon,
  &__presentation-icon {
    display: flex;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__presentation-link {
    padding: 10px;
    margin: -10px;
  }

  .video-layout {
    transition: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: var(--default-transition-function);
  }

  &.need-animation {
    .animation-mask__line span {
      transform: translate3d(0, 110%, 0);
    }
  }
}

@include respond-up('large') {
  .about {
    &__description-container {
      grid-column: 9/12;
      margin-top: 71px;
    }

    &__icon-container {
      margin-top: 231px;
      grid-row: 3;

      &::before {
        top: calc((16 / 1920) * 100vw);
        height: calc(100% - ((16 / 1920) * 100vw));
      }
    }

    &__title {
      margin-top: 57px;
    }

    &__icon {
      width: 296px;
      height: 36px;
      margin-top: 133px;
    }

    &__presentation-container {
      display: none;
    }
  }
}

@include respond-up('s-large') {
  .about {
    padding: 35px 0 60px;

    &__grid {
      grid-template-columns: repeat(12, 1fr);
    }

    &__title-container {
      grid-row: 1/4;
    }

    &__title-sticky {
      position: sticky;
      top: calc(35px + var(--header-height));
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      max-height: calc(100vh - (35px + var(--header-height) + 60px));
    }

    &__picture-container {
      grid-column: 9/13;
      grid-row: 1;
      height: calc(var(--grid-column4) * (422 / 593));
      margin-top: 635px;
    }

    &__description-container {
      grid-row: 2;
    }

    &__presentation {
      &_desktop {
        margin-top: auto;
      }
    }

    &__icon-container {
      grid-column: 9/13;
    }
  }
}

@include respond('s-large') {
  .about {
    &__description-container {
      grid-column: 9/13;
      margin-top: 53px;
    }

    &__icon-container {
      margin-top: 183px;
      grid-row: 4;

      &::before {
        top: calc((12 / 1280) * 100vw);
        height: calc(100% - ((12 / 1280) * 100vw));
      }
    }

    &__title {
      margin-top: 41px;
    }

    &__icon {
      margin-top: 93px;
    }
  }
}

@include respond-down('s-large') {
  .about {
    &__icon {
      width: 197px;
      height: 24px;
    }

    &__presentation {
      &_desktop {
        display: none;
      }
    }
  }
}

@include respond-up('medium') {
  .about {
    &__description {
      &_bottom {
        margin-top: 24px;
      }
    }

    &__icon-container {
      &::before {
        left: calc(var(--grid-gap) * -1);
      }
    }

    &__presentation-icon {
      width: 69px;
      height: 84px;
      margin-bottom: 24px;
    }
  }
}

@include respond('medium') {
  .about {
    padding: 25px 0 40px;

    &__picture-container {
      margin-top: 53px;
    }

    &__description-container {
      grid-column: 4/7;
      margin-top: 53px;
    }

    &__icon-container {
      grid-column: 4/7;
      margin-top: 113px;

      &::before {
        top: calc((12 / 834) * 100vw);
        height: calc(100% - ((12 / 834) * 100vw));
      }
    }

    &__title {
      margin-top: 59px;
    }

    &__icon {
      margin-top: 73px;
    }
  }
}

@include respond-down('medium') {
  .about {
    &__grid {
      grid-template-columns: repeat(6, 1fr);
    }

    &__picture-container {
      grid-column: 1/7;
      height: calc(var(--grid-column6) * (422 / 593));
    }

    &__presentation-container {
      margin-top: 53px;
      grid-row: 4;
    }
  }
}

@include respond-down('small') {
  .about {
    padding: 15px 0 30px;

    &__picture-container {
      margin-top: 36px;
    }

    &__description-container {
      grid-column: 1/7;
      margin-top: 36px;
    }

    &__icon-container {
      grid-column: 1/5;
      margin-top: 63px;
      padding-left: 20px;

      &::before {
        left: 0;
        top: calc((7 / 375) * 100vw);
        height: calc(100% - ((7 / 375) * 100vw));
      }
    }

    &__title {
      margin-top: 36px;
    }

    &__description {
      &_bottom {
        margin-top: 21px;
      }
    }

    &__icon {
      width: 161px;
      height: 20px;
      margin-top: 36px;
    }

    &__presentation-icon {
      width: 44px;
      height: 54px;
      margin-bottom: 10px;
    }
  }
}