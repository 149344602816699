.hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;

  &__icon {
    position: relative;
    height: 30px;
    overflow: hidden;
  }

  &__line {
    position: absolute;
    height: 2px;
    background-color: var(--primary-color);
    transition: top, bottom, transform, background-color;
    transition-timing-function: var(--default-transition-function);
    transition-duration: .3s;

    &:nth-child(1) {
      left: 0;
      top: 0;
    }

    &:nth-child(2) {
      left: 0;
      bottom: 0;
      transition-delay: 0s, 0s, .1s, 0s;
    }

    &:nth-child(3) {
      left: -50px;
      top: 0;
    }

    &:nth-child(4) {
      left: -50px;
      bottom: 0;
      transition-delay: 0s, 0s, .1s, 0s;
    }
  }

  &__title {
    margin-left: 20px;
    transition: color .3s var(--default-transition-function);
  }

  &._opened {
    .hamburger {
      &__line {

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 14px;
        }

        &:nth-child(4) {
          transform: rotate(-135deg);
          bottom: 14px;
        }
      }
    }
  }

  &._light {
    .hamburger {
      &__line {
        background-color: #fff;
      }

      &__title {
        color: #fff;
      }
    }
  }
}

@include respond-up('s-large') {
  .hamburger {
    &:hover {
      .hamburger {
        &__line {
          transform: translate3d(calc(100% + 10px), 0, 0);
        }
      }
    }

    &._opened {
      .hamburger {
        &__line {
          transform: translate3d(calc(100% + 10px), 0, 0);

          &:nth-child(3) {
            transform: translate3d(calc(100% + 10px), 0, 0) rotate(-45deg);
            top: 14px;
          }

          &:nth-child(4) {
            transform: translate3d(calc(100% + 10px), 0, 0) rotate(-135deg);
            bottom: 14px;
          }
        }
      }
    }

    &._closing {
      .hamburger {
        &__line {
          transform: translate3d(calc(100% + 10px), 0, 0);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .hamburger {
    &__icon {
      width: 40px;
    }

    &__line {
      width: 36px;

      &:nth-child(1),
      &:nth-child(3) {
        top: 8px;
      }

      &:nth-child(2),
      &:nth-child(4) {
        bottom: 8px;
      }
    }
  }
}

@include respond-down('medium') {
  .hamburger {
    &__line {
      &:nth-child(1),
      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        left: 0;
        top: 10px;
      }

      &:nth-child(4) {
        left: 0;
        bottom: 10px;
      }
    }
  }
}

@include respond-down('small') {
  .hamburger {
    &__line {
      width: 30px;
    }

    &__icon {
      width: 36px;
    }

    &__title {
      display: none;
    }
  }
}