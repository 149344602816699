.floating-field {
  position: relative;

  label {
    position: absolute;
    z-index: 10;
    left: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    transition: all .3s;
    margin: 0;

    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  &._filled input + label,
  input:focus + label,
  &._filled textarea + label,
  textarea:focus + label {
    top: 0;
    color: var(--primary-color-40);
  }

  &_textarea {
    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 15px;
      background-color: #fff;
    }
  }
}

@include respond-up('s-large') {
  .floating-field {
    textarea {
      max-height: 131px;
    }
  }
}

@include respond-up('medium') {
  .floating-field {
    label {
      font-size: 17px;
      top: 15px;
    }

    &._filled input + label,
    input:focus + label,
    &._filled textarea + label,
    textarea:focus + label {
      top: -5px;
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .floating-field {
    textarea {
      max-height: 107px;
    }
  }
}

@include respond-down('small') {
  .floating-field {
    label {
      font-size: 15px;
      top: 10px;
    }

    &._filled input + label,
    input:focus + label,
    &._filled textarea + label,
    textarea:focus + label {
      top: -3px;
      font-size: 12px;
    }

    textarea {
      max-height: 154px;
    }

    &_textarea {
      &::before {
        height: 13px;
      }
    }
  }
}