.video-layout {
  display: flex;
  width: 100%;
  height: 100%;

  &__inner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__meta {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
}