.picture-block {
  position: relative;
  display: flex;

  &__picture-wrap {
    position: relative;
    z-index: 5;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      z-index: 20;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--primary-color-10);
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 20;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 460px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%);
    }

    &_mobile {
      display: none;
    }
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content-wrap {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    padding: 0 var(--grid-spacer);
    pointer-events: none;
  }

  &__content {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-row-gap: 0;
    grid-template-rows: 1fr max-content;
    width: 100%;
    height: 100%;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: transform, opacity;
    transition-duration: 1.3s;
    transition-timing-function: var(--default-transition-function);

    &.need-animation {
      transform: scaleX(0.83);
      opacity: 0;
    }
  }

  &__title,
  &__description {
    color: #fff;
  }

  &__description {
    transition: opacity 1s var(--default-transition-function) .1s;

    &.need-animation {
      opacity: 0;
    }
  }
}

@include respond-up("large") {
  .picture-block {
    &__content {
      padding: 75px 0;
    }

    &__description {
      grid-column: 9/12;
    }
  }
}

@include respond-up("s-large") {
  .picture-block {
    height: calc(100vh - var(--header-height));

    &__content {
      grid-template-columns: repeat(12, 1fr);
    }

    &__title {
      grid-column: 1/13;
    }
  }
}

@include respond("s-large") {
  .picture-block {
    &__content {
      padding: 55px 0;
    }

    &__description {
      grid-column: 9/13;
    }
  }
}

@include respond("medium") {
  .picture-block {
    height: calc((635 / 834) * 100vw);

    &_description {
      height: calc((825 / 834) * 100vw);
    }

    &__content {
      padding: 75px 0;
    }

    &__description {
      grid-column: 4/7;
    }
  }
}

@include respond-down("medium") {
  .picture-block {
    &__content {
      grid-template-columns: repeat(6, 1fr);
    }

    &__picture-wrap {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: flex;
      }
    }

    &__title {
      grid-column: 1/7;
    }
  }
}

@include respond-down("small") {
  .picture-block {
    height: calc((383 / 375) * 100vw);

    &_description {
      height: calc((459 / 375) * 100vw);
    }

    &__content {
      padding: 45px 0;
    }

    &__picture-wrap {
      &::after {
        height: 417px;
      }
    }

    &__description {
      grid-column: 1/7;
    }
  }
}
