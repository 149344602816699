.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 12000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  transition: opacity 1s var(--default-transition-function) 2.9s;
  pointer-events: none;
  opacity: 0;

  &.need-animation {
    opacity: 1;

    .preloader {
      &__inner {
        opacity: 1;
      }

      &__lines {
        width: 123px;

        &:before,
        &:after {
          transform: scale3d(0, 1, 1);
        }
      }

      &__letter {
        svg {
          transform: translate3d(0, 100%, 0);
        }

        &_i-first,
        &_i-last {
          svg {
            transform: translate3d(0, -100%, 0);
          }
        }

        &_v,
        &_i-first,
        &_i-last,
        &_o {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: center;
    width: 384px;
    opacity: 0;
    transition: opacity .3s var(--default-transition-function) 2.9s;
  }

  &__lines {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 100%;
    height: 100%;
    transition: width .8s var(--default-transition-function) 1.8s;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background: #fff;
      height: 4px;
      width: 100%;
      left: 0;
      transition: transform 1s var(--default-transition-function) .4s;
    }

    &:before {
      top: 0;
      transform-origin: left;
    }

    &:after {
      bottom: 0;
      transform-origin: right;
    }
  }

  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    width: 123px;
  }

  &__letter {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: transform .8s var(--default-transition-function) 1.8s;

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        svg {
          transition-delay: calc(.4s + ((#{$i} - 1) * .1s));
        }
      }
    }

    svg {
      transition: transform 1s var(--default-transition-function);
    }

    &_v {
      transform: translate3d(calc((384 - 123) / 2 * -1px), 0, 0);
    }

    &_i-first {
      transform: translate3d(calc((384 - 123) / 4 * -1px), 0, 0);
    }

    &_i-last {
      transform: translate3d(calc((384 - 123) / 4 * 1px), 0, 0);
    }

    &_o {
      transform: translate3d(calc((384 - 123) / 2 * 1px), 0, 0);
    }
  }
}

@include respond-down('small') {
  .preloader {
    &__inner {
      width: 325px;
      transform: scale(0.5);
    }

    &__letter {
      &_v {
        transform: translate3d(-100px, 0, 0);
      }

      &_i-first {
        transform: translate3d(-50px, 0, 0);
      }

      &_i-last {
        transform: translate3d(50px, 0, 0);
      }

      &_o {
        transform: translate3d(100px, 0, 0);
      }
    }
  }
}