.team-slider {
  &__main {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__title-container {
    display: flex;
    position: relative;
    z-index: 10;
  }

  &__title {
    color: #fff;
    width: 100%;
  }

  &__slider-container {
    position: relative;
    overflow-x: hidden;
    z-index: 5;
    padding-right: var(--grid-spacer);
    margin-right: calc(var(--grid-spacer) * -1);
  }

  &__slider {
    &.swiper {
      overflow: visible;
    }

    &._animation-off {
      .team-slider {
        &__image-wrap {
          transition-duration: 0s;
        }
      }
    }
  }

  &__item {
    width: max-content !important;

    .team-slider__image-wrap {
      width: var(--active-slide-width);
      height: var(--active-slide-height);
    }

    &.swiper-slide-active {
      ~ .team-slider__item {
        .team-slider__image-wrap {
          width: var(--inactive-slide-width);
          height: var(--inactive-slide-height);
        }
      }
    }

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        .team-slider__picture {
          transition-delay: calc(#{$i} * 0.05s);
        }
      }
    }
  }

  &__image-wrap {
    overflow: hidden;
    transition: width, height;
    transition-duration: .4s;
    transition-timing-function: var(--default-transition-function);
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform, opacity;
    transition-duration: .7s;
    transition-timing-function: var(--default-transition-function);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__text-slider-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: var(--grid-column3);
  }

  &__text-slider {
    height: 100%;
  }

  &__title-slider-list,
  &__job-title-slider-list {
    &.swiper-wrapper {
      transform: translate3d(0, 0, 0) !important;
    }
  }

  &__text-position {
    color: #fff;
  }

  &__text-name {
    color: #fff;
    width: 100%;
  }

  &__text-name-inner {
    overflow: hidden;

    span {
      animation: team-slide-title-out .4s var(--default-transition-function) forwards;
    }
  }

  &__text-position {
    opacity: 0;
    transition: opacity .4s var(--default-transition-function);
    animation: team-slide-title-out .4s var(--default-transition-function) forwards;
    margin-top: auto;
  }

  &__slider-wrap {
    position: relative;
  }

  &__title-slider-wrap {
    position: absolute;
    pointer-events: none;
  }

  &__title-slider,
  &__title-slider-list {
    width: 100%;
    height: 100%;
  }

  &__title-slide {
    &.swiper-slide {
      position: absolute;
      bottom: 0;
      left: 0;
      height: max-content;
      width: 100%;
    }

    &.swiper-slide-active {
      .team-slider {
        &__text-name-inner {
          span {
            animation: team-slide-title-in .4s var(--default-transition-function) forwards;
          }
        }
      }
    }
  }

  &__job-title-slide {
    &.swiper-slide {
      position: absolute;
      top: 0;
      left: 0;
      height: max-content;
    }

    &.swiper-slide-active {
      .team-slider {
        &__text-position {
          opacity: 1;
          animation: team-slide-title-in .4s var(--default-transition-function) forwards;
        }
      }
    }
  }

  &.need-animation {
    .animation-mask__line span {
      transform: translate3d(0, 110%, 0);
    }

    .team-slider {
      &__text-position {
        opacity: 0;
      }

      &__picture {
        transform: scale3d(1.1, 1.1, 1);
        opacity: 0;
      }
    }
  }
}

@include respond-up('large') {
  .team-slider {
    &__title-container {
      padding-top: 59px;
    }

    &__title {
      margin-bottom: 73px;
    }

    &__text-slider-wrap {
      height: 106px;
    }

    &__title-slider-wrap {
      bottom: 25px;
      left: 30px;
      width: calc(var(--grid-column3) - 60px);
      height: calc(100% - 50px);
    }

    &__title-slide {
      &.swiper-slide {
        width: calc(var(--grid-column3) - 60px);
      }
    }
  }
}

@include respond-up('s-large') {
  .team-slider {
    &__main {
      grid-template-columns: repeat(12, 1fr);
    }

    &__title-container {
      grid-column: 1/5;
      flex-direction: column;
    }

    &__slider-container {
      grid-column: 5/13;
    }
  }
}

@include respond('s-large') {
  .team-slider {
    &__title {
      margin-bottom: 53px;
    }

    &__text-slider-wrap {
      height: 88px;
    }
  }
}

@include respond-down('s-large') {
  .team-slider {
    &__title-container {
      padding-top: 43px;
    }
  }
}

@include respond-up('medium') {
  .team-slider {
    --active-slide-width: var(--grid-column3);
    --active-slide-height: calc(var(--grid-column3) * (483 / 438));
    --inactive-slide-width: var(--grid-column2);
    --inactive-slide-height: calc(var(--grid-column2) * (311 / 281));

    &__job-title-slider-wrap {
      width: var(--grid-column3);
      padding-top: 15px;
    }
  }
}

@include respond-between('medium', 's-large') {
  .team-slider {
    &__title-slider-wrap {
      bottom: 20px;
      left: 15px;
      width: calc(var(--grid-column3) - 30px);
      height: calc(100% - 40px);
    }

    &__title-slide {
      &.swiper-slide {
        width: calc(var(--grid-column3) - 30px);
      }
    }
  }
}

@include respond('medium') {
  .team-slider {
    &__main {
      grid-row-gap: 53px;
    }

    &__title-container {
      grid-column: 1/7;
      align-items: flex-end;
      justify-content: space-between;
    }

    &__title {
      width: var(--grid-column3);
    }

    &__text-slider-wrap {
      height: 97px;
    }
  }
}

@include respond-down('medium') {
  .team-slider {
    &__main {
      grid-template-columns: repeat(6, 1fr);
    }

    &__slider-container {
      grid-column: 1/7;
    }
  }
}

@include respond-down('small') {
  .team-slider {
    --active-slide-width: var(--grid-column4);
    --active-slide-height: calc(var(--grid-column4) * (483 / 438));
    --inactive-slide-width: var(--grid-column3);
    --inactive-slide-height: calc(var(--grid-column3) * (311 / 281));

    &__main {
      grid-row-gap: 36px;
    }

    &__title-container {
      grid-column: 1/5;
      padding-top: 29px;
    }

    &__arrows {
      display: none;
    }

    &__text-slider-wrap {
      width: var(--grid-column4);
      height: 68px;
    }

    &__title-slider-wrap {
      bottom: 10px;
      left: 7px;
      width: calc(var(--grid-column4) - 14px);
      height: calc(100% - 20px);
    }

    &__title-slide {
      &.swiper-slide {
        width: calc(var(--grid-column4) - 14px);
      }
    }

    &__job-title-slider-wrap {
      width: var(--grid-column4);
      padding-top: 9px;
    }
  }
}

@keyframes team-slide-title-in {
  from {
    transform: translate3d(0, 110%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes team-slide-title-out {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, -110%, 0);
  }
}