.contacts-content-item {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__info-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: var(--grid-gap);
    flex: 0 0 auto;
  }

  &__info-item {
    grid-column: span 1;
  }

  &__info-item-title {
    color: var(--primary-color-40);
  }

  &__open-hours-item {
    display: flex;
    align-items: center;
  }

  &__make-route {
    width: 100%;
  }

  &__presentation {
    padding-bottom: 4px;
  }

  &__presentation-icon {
    display: flex;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__presentation-link {
    padding: 10px;
    margin: -10px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
  }
}

@include respond-up('s-large') {
  .contacts-content-item {
    &__info-list {
      grid-row-gap: 50px;
    }

    &__buttons {
      margin-top: 55px;
    }

    &__presentation {
      margin-top: auto;
    }
  }
}

@include respond-up('medium') {
  .contacts-content-item {
    padding-top: 25px;

    &__info-item-value {
      margin-top: 18px;
    }

    &__presentation-icon {
      width: 69px;
      height: 84px;
      margin-bottom: 24px;
    }
  }
}

@include respond('medium') {
  .contacts-content-item {
    &__info-list {
      grid-row-gap: 40px;
    }

    &__buttons {
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 75px;
    }

    &__make-route {
      width: var(--grid-column3);
    }
  }
}

@include respond-down('small') {
  .contacts-content-item {
    padding-top: 15px;

    &__info-list {
      grid-row-gap: 30px;
    }

    &__info-item {
      &_address,
      &_open-hours {
        grid-column: 1/3;
      }
    }

    &__info-item-value {
      margin-top: 5px;
    }

    &__buttons {
      margin-top: 45px;
    }

    &__presentation {
      margin-top: 40px;
    }

    &__presentation-icon {
      width: 44px;
      height: 54px;
      margin-bottom: 10px;
    }
  }
}