.hero-slider {
  position: relative;
  overflow: hidden;

  .video-layout {
    transition: transform 0s var(--default-transition-function);
    transform: scale3d(1.2, 1.2, 1);
  }

  &__arrows {
    pointer-events: auto;
    width: max-content;
  }

  &__picture-wrap {
    position: absolute;
    display: flex;
    width: 100vw;
    height: 100%;

    &_mobile {
      display: none;
    }
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0s var(--default-transition-function);
    transform: scale3d(1.2, 1.2, 1);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__list {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 15;
    overflow: hidden;
    touch-action: pan-y;

    &::before {
      content: '';
      position: absolute;
      z-index: 20;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--primary-color-10);
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 20;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 460px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%);
    }
  }

  &__item {
    position: absolute;
    display: block;
    height: 100%;
    z-index: 5;
    overflow: hidden;
    transition: transform 0s, width 0s;
    transition-timing-function: var(--default-transition-function);

    &._active {
      z-index: 10;
      width: 100%;
      transform: translate3d(0, 0, 0);

      .hero-slider__picture,
      .video-layout {
        transition-duration: 1.2s;
        transform: scale3d(1, 1, 1);
      }
    }

    &._in-next-act {
      animation: hero-image-next 1.2s var(--default-transition-function) forwards;
    }

    &._out-next-act {
      z-index: 15;
      width: 0;
      transform: translate3d(0, 0, 0);
      transition-duration: 0s, 1.2s;
      left: 0;

      .hero-slider__picture-wrap {
        left: 0;
      }

      .hero-slider__picture,
      .video-layout {
        transition-duration: 1.2s;
        transform: translate3d(-15%, 0, 0) scale3d(1, 1, 1);
      }
    }

    &._in-prev-act {
      animation: hero-image-prev 1.2s var(--default-transition-function) forwards;
    }

    &._out-prev-act {
      z-index: 15;
      width: 0;
      transform: translate3d(0, 0, 0);
      transition-duration: 0s, 1.2s;
      right: 0;

      .hero-slider__picture-wrap {
        right: 0;
      }

      .hero-slider__picture,
      .video-layout {
        transition-duration: 1.2s;
        transform: translate3d(15%, 0, 0) scale3d(1, 1, 1);
      }
    }
  }

  &__content-wrap {
    position: absolute;
    bottom: 0;
    z-index: 25;
    width: 100%;
    pointer-events: none;
  }

  &__content {
    grid-row-gap: 0;
    grid-template-rows: 1fr max-content;
  }

  &__content-bottom {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__title-slider {
    position: relative;
    grid-row: 1;
  }

  &__title-slide {
    display: flex;
    position: absolute;
    left: 0;
// TITLE CENTER
    &_title-center {
      top: 50%;
      transform: translate3d(0, -50%, 0);
      width: 100%;
      text-align: center;

      .hero-slider__title {
        opacity: 0;
        transition-duration: .3s;
        transform: scaleX(0.83) translate3d(var(--lp-translate-h2-half), 0, 0);
      }

      &._active {
        .hero-slider {
          &__title {
            transform: scaleX(1) translate3d(var(--lp-translate-h2-half), 0, 0);
            opacity: 1;
            transition-duration: 1.3s;
            transition-delay: .2s;
          }
        }
      }

      &._out-next-act {
        .hero-slider__title {
          transform: translate3d(-60px, 0, 0) scaleX(1);
        }
      }

      &._out-prev-act {
        .hero-slider__title {
          transform: translate3d(60px, 0, 0) scaleX(1);
        }
      }
    }
// TITLE SIDE
    &_title-side {
      top: 0;
      width: var(--grid-column6);

      .hero-slider__title {
        opacity: 0;

        &._text-wrap-creator-container {
          opacity: 1;
        }
      }

      &._active {
        .hero-slider {
          &__title-inner {
            span {
              transform: translate3d(0, 0, 0);
              transition-duration: 1s;
            }

            @for $i from 2 through 10 {
              &:nth-child(#{$i}) {
                span {
                  transition-delay: calc(#{$i} * .1s + ((#{$i} - 2) * .1s));
                }
              }
            }
          }
        }
      }

      &._out-next-act,
      &._out-prev-act {
        .hero-slider {
          &__title-inner {
            span {
              transition-duration: .4s;
              transform: translate3d(0, -110%, 0);
            }
          }
        }
      }
    }

    &_textless {
      display: none;
    }
  }

  &__title {
    transition: opacity, transform;
    transition-timing-function: var(--default-transition-function);
    color: #fff;
    width: 100%;
  }

  &__title-inner {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: max-content;

    span {
      transform: translate3d(0, 110%, 0);
      transition: transform .0s var(--default-transition-function);
    }
  }

  &__description-slider {
    position: relative;
  }
// DESCRIPTION SLIDER
  &__description-slide {
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: max-content;
    opacity: 0;
    transition: opacity, transform;
    transition-duration: .0s;
    transition-timing-function: var(--default-transition-function);

    &._active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition-duration: .7s;
    }

    &._in-next-act {
      opacity: 0;
      transform: translate3d(80px, 0, 0);
    }

    &._in-prev-act {
      opacity: 0;
      transform: translate3d(-80px, 0, 0);
    }

    &._out-next-act {
      transition-duration: .4s;
      transform: translate3d(-80px, 0, 0);
    }

    &._out-prev-act {
      transition-duration: .4s;
      transform: translate3d(80px, 0, 0);
    }
  }

  &__description {
    color: #fff;
  }

  &__pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    display: flex;
    align-items: center;
    margin: 0 -5px;
  }

  &__pagination-item {
    width: 34px;
    height: 2px;
    background-color: var(--white-color-40);
    margin: 0 5px;
    transition: background-color .3s var(--default-transition-function);

    &._active {
      background-color: #fff;
    }
  }
}

@include respond-up('large') {
  .hero-slider {
    &__content {
      padding: var(--header-height) 0 75px;
    }

    &__description-slide {
      width: var(--grid-column3);
    }

    &__title-slide {
      &_title-side {
        padding: 62px 0;
      }
    }
  }
}

@include respond('s-large') {
  .hero-slider {
    &__content {
      padding: var(--header-height) 0 55px;
    }

    &__title-slide {
      &_title-side {
        padding: calc(62 / 980 * 100vh) 0;
      }
    }
  }
}

@include respond-up('s-large') {
  .hero-slider {
    height: 100vh;

    &__content {
      height: 100vh;
    }

    &__title-slider,
    &__content-bottom {
      grid-column: 1/13;
    }

    &__title-slide {
      &_title-center {
        .hero-slider__title-inner {
          max-width: var(--grid-column12);
        }
      }
    }

    &__description-slider {
      width: var(--grid-column4);
    }

    &__arrows {
      margin-bottom: 5px;
    }

    &__pagination {
      bottom: 5px;
    }
  }
}

@include respond-up('medium') {
  .hero-slider {
    &__item {
      &_mobile {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .hero-slider {
    height: calc((948 / 834) * 100vw);

    &__content {
      padding: var(--header-height) 0 50px;
      height: calc((948 / 834) * 100vw);
    }

    &__title-slide {
      &_title-side {
        padding: 64px 0;
        width: var(--grid-column5);
      }
    }

    &__title-inner {
      max-width: var(--grid-column8);
    }

    &__description-slider {
      width: var(--grid-column3);
    }

    &__description-slide {
      padding-bottom: 80px;
    }
  }
}

@include respond-down('medium') {
  .hero-slider {
    &__title-slider,
    &__content-bottom {
      grid-column: 1/7;
    }

    &__title-slide {
      &_title-center {
        .hero-slider__title-inner {
          max-width: var(--grid-column6);
        }
      }
    }

    &__picture-wrap {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: flex;
      }
    }

    &__description-slide {
      top: unset;
      bottom: 0;
    }
  }
}

@include respond-down('small') {
  .hero-slider {
    height: calc((598 / 375) * 100vw);

    &__content {
      padding: var(--header-height) 0 30px;
      height: calc((598 / 375) * 100vw);
    }

    &__list {
      &::after {
        height: 417px;
      }
    }

    &__title-slide {
      &_title-side {
        padding: 30px 0;
      }
    }

    &__arrows {
      display: none;
    }

    &__item {
      &_desktop {
        display: none;
      }
    }

    &__description-slider {
      width: var(--grid-column6);
    }

    &__pagination-item {
      width: 24px;
    }

    &__description-slide {
      padding-bottom: 50px;
    }
  }
}

@keyframes hero-image-next {
  from {
    transform: translate3d(15%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hero-image-prev {
  from {
    transform: translate3d(-15%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}