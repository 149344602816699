.modal-form-success {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: var(--grid-column4);
  }

  &__subtitle {
    text-align: center;
    width: var(--grid-column4);
  }

  &__subtitle {
    transition: opacity, transform;
    transition-duration: .4s;
    transition-delay: .3s;
    transition-timing-function: var(--default-transition-function);
  }

  &.need-animation {
    .animation-mask__line span {
      transform: translate3d(0, 110%, 0);
    }

    .modal-form-success__subtitle {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
    }
  }
}

@include respond-up('s-large') {
  .modal-form-success {
    height: 100%;
    width: var(--modal-content-width);

    &__subtitle {
      margin-top: 56px;
    }
  }
}

@include respond-up('medium') {
  .modal-form-success {
    &__subtitle {
      width: var(--grid-column2);
    }

    &_review {
      .modal-form-success {
        &__subtitle {
          width: var(--grid-column3);
        }
      }
    }
  }
}

@include respond('medium') {
  .modal-form-success {
    &__subtitle {
      margin-top: 36px;
    }
  }
}

@include respond-down('medium') {
  .modal-form-success {
    width: 100%;
    height: var(--modal-content-height);
  }
}

@include respond-down('small') {
  .modal-form-success {
    &__subtitle {
      margin-top: 21px;
    }
  }
}