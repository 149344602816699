.arrows {
  display: flex;
  align-items: center;

  &__right {
    transform: translate3d(-1px, 0, 0);
  }

  &_light {
    .arrow {
      border-color: #fff;
      background-color: var(--t);

      .arrow {
        &__background {
          background-color: #fff;
        }

        &__icon svg path {
          stroke: #fff;
        }
      }
    }
  }
}