.open-system {
  &__inner {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__top {
    display: flex;
  }

  &__title,
  &__description {
    color: #fff;
  }

  &__title {
    width: var(--grid-column4);
  }

  &__description {
    width: var(--grid-column3);
  }

  &__description-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }

  &__block-heading {
    width: 100%;
  }

  &__slider-wrap {
    overflow: hidden;
    margin: 0 calc(var(--grid-spacer) * -1);
    padding: 0 var(--grid-spacer);
  }

  &__slider {
    &.swiper {
      overflow: visible;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    background: var(--graphit-color);

    &_mobile {
      display: none;
    }
  }

  &__counter {
    color: #fff;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__progressbar-wrap {
    display: none;
  }

  &.need-animation {
    .animation-mask__line span {
      transform: translate3d(0, 110%, 0);
    }
  }
}

@include respond-up('large') {
  .open-system {
    &__inner {
      margin-top: 59px;
      grid-row-gap: 73px;
    }

    &__top {
      align-items: flex-end;
      justify-content: space-between;
    }

    &__description-wrap {
      width: var(--grid-column4);
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column8);
      }
    }

    &__image-wrap {
      width: calc(var(--grid-column8) - 60px);
      height: calc((var(--grid-column8) - 60px) * (465 / 1157));
    }

    &__description {
      width: calc(var(--grid-column3) - var(--grid-column) / 2);
    }
  }
}

@include respond-up('s-large') {
  .open-system {
    &__inner {
      grid-template-columns: repeat(12, 1fr);
    }

    &__top,
    &__slider-wrap {
      grid-column: 1/13;
    }

    &__image-wrap {
      margin-top: 25px;
    }

    &__item {
      padding: 25px 30px 30px;
    }
  }
}

@include respond('s-large') {
  .open-system {
    &__title {
      width: var(--grid-column5);
    }

    &__description-wrap {
      width: 100%;
      margin-top: 46px;
    }

    &__description {
      width: var(--grid-column4);
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column10);
      }
    }

    &__image-wrap {
      width: calc(var(--grid-column10) - 60px);
      height: calc((var(--grid-column10) - 60px) * (375 / 935));
    }
  }
}

@include respond-down('s-large') {
  .open-system {
    &__inner {
      margin-top: 43px;
      grid-row-gap: 55px;
    }

    &__top {
      flex-direction: column;
    }
  }
}

@include respond('medium') {
  .open-system {
    &__description-wrap {
      margin-top: 39px;
    }

    &__item {
      padding: 15px 20px 20px;

      &.swiper-slide {
        width: var(--grid-column4);
      }
    }

    &__image-wrap {
      margin-top: 15px;
      width: calc(var(--grid-column4) - 40px);
      height: calc((var(--grid-column4) - 40px) * (525 / 469));
    }
  }
}

@include respond-down('medium') {
  .open-system {
    &__inner {
      grid-template-columns: repeat(6, 1fr);
    }

    &__top,
    &__slider-wrap {
      grid-column: 1/7;
    }

    &__item {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: flex;
      }
    }
  }
}

@include respond-down('small') {
  .open-system {
    &__inner {
      margin-top: 29px;
      grid-row-gap: 0;
    }

    &__slider-wrap {
      margin-top: 35px;
    }

    &__title {
      width: var(--grid-column6);
    }

    &__description-wrap {
      margin-top: 31px;
    }

    &__description {
      width: 100%;
    }

    &__arrows {
      display: none;
    }

    &__item {
      padding: 9px 14px 14px;

      &.swiper-slide {
        width: var(--grid-column5);
      }
    }

    &__image-wrap {
      margin-top: 10px;
      width: calc(var(--grid-column5) - 28px);
      height: calc((var(--grid-column5) - 28px) * (282 / 252));
    }

    &__progressbar-wrap {
      position: relative;
      grid-column: 3/5;
      display: block;
      margin-top: 27px;
    }

    &__progressbar {
      --swiper-pagination-color: #fff;

      &.swiper-pagination-progressbar.swiper-pagination-horizontal {
        height: 2px;
      }

      &.swiper-pagination-progressbar {
        background-color: var(--white-color-40);
      }
    }
  }
}