.footer {
  background: var(--primary-color);

  &__wrap {
    display: grid;
    grid-column-gap: var(--grid-gap);
    grid-row-gap: 0;
  }

  &__files {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-column: 1/4;
  }

  &__nav-list {
    display: grid;
    grid-column-gap: var(--grid-gap);
    grid-template-columns: repeat(2, 1fr);
    margin: -4px;
  }

  &__nav-item {
    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        grid-row: #{$i};
      }
    }
  }

  &__nav-link {
    padding: 4px;
  }

  &__logo {
    display: flex;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__rclass-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__rclass-link {
    padding: 10px;
    margin: -10px;
    color: var(--white-color-40);
  }

  &__copy {
    color: #fff;
  }

  &__rclass-title {
    color: #fff;
  }

  &__policy {
    padding: 10px 10px 0;
    margin: -10px -10px 0;
  }

  &__requisites {
    padding: 0 10px 10px;
    margin: 0 -10px -10px;
  }

  &__presentation-wrap {
    display: flex;
    align-items: center;
  }

  &__presentation {
    padding: 10px;
    margin: -10px;
  }

  &__nav-link,
  &__presentation,
  &__policy,
  &__requisites {
    .button__title::before {
      transform: scaleX(0);
      transform-origin: center right;
    }
  }
}

@include respond-up('s-large') {
  .footer {
    padding: 55px 0 40px;

    &__wrap {
      grid-template-columns: repeat(12, 1fr);
    }

    &__nav-container {
      grid-column: 5/9;
      margin-bottom: 220px;
    }

    &__nav-link,
    &__presentation,
    &__policy,
    &__requisites {
      &:hover {
        .button__title::before {
          transform: scaleX(1);
          transform-origin: left center;
        }
      }
    }

    &__socials {
      grid-column: 10/13;
    }

    &__presentation-wrap {
      grid-column: 5/7;
    }

    &__rclass-wrap {
      grid-column: 11/13;
    }

    &__logo {
      grid-column: 1/13;
    }

    &__rclass-title {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid #fff;
        transition: transform .3s var(--default-transition-function);
        transform: scaleX(0);
        transform-origin: center right;
      }
    }

    &__rclass-link {
      &:hover {
        .footer__rclass-title::before {
          transform: scaleX(1);
          transform-origin: left center;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__socials {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }

    &__copy {
      grid-column: 1/3;
    }

    &__logo {
      margin-top: 25px;
    }
  }
}

@include respond('medium') {
  .footer {
    padding: 35px 0 30px;

    &__nav-container {
      margin-bottom: 120px;
    }

    &__socials,
    &__files {
      margin-bottom: 60px;
    }

    &__socials {
      grid-column: 5/7;
      grid-row: 1;
    }

    &__presentation-wrap {
      grid-column: 4/5;
    }

    &__rclass-wrap {
      grid-column: 5/7;
    }

    &__logo {
      grid-column: 1/7;
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__wrap {
      grid-template-columns: repeat(6, 1fr);
    }

    &__nav-container {
      grid-column: 1/7;
    }
  }
}

@include respond-down('small') {
  .footer {
    padding: 20px 0;

    &__nav-container {
      margin-top: 42px;
      margin-bottom: 93px;
    }

    &__nav-link {
      padding: 4px;
    }

    &__socials {
      grid-column: 1/5;
      grid-row: 1;
    }

    &__files {
      grid-row: 2;
      margin-top: 27px;
    }

    &__presentation-wrap {
      grid-column: 5/7;
      grid-row: 1;
      justify-content: flex-end;
    }

    &__presentation {
      margin: -23px -10px -10px;
    }

    &__rclass-wrap {
      grid-column: 4/7;
    }

    &__copy {
      grid-column: 1/4;
    }

    &__logo {
      grid-column: 1/7;
      margin-top: 10px;
    }
  }
}