.certificates {
  &__wrap {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__title,
  &__item-value {
    color: #fff;
  }

  &__item-value {
    transition: color var(--default-timing) var(--default-transition-function);
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    transition: border-bottom-color var(--default-timing) var(--default-transition-function);
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item-value {
    &_file {
      text-transform: uppercase;
    }
  }
}

@include respond-up('large') {
  .certificates {
    &__item-value {
      &_name {
        flex: 0 0 var(--grid-column4);
      }

      &_subtitle {
        flex: 0 0 var(--grid-column2);
      }

      &_file {
        flex: 0 0 var(--grid-column1);
      }
    }
  }
}

@include respond-up('s-large') {
  .certificates {
    &__wrap {
      grid-template-columns: repeat(12, 1fr);
      border-top: 1px solid #fff;
    }

    &__title {
      grid-column: 1/5;
      margin-top: 15px;
    }

    &__list-wrap {
      grid-column: 5/13;
    }

    &__list {
      &:hover {
        .certificates {
          &__link {
            border-bottom-color: var(--white-color-40);
          }

          &__item-value {
            color: var(--white-color-40);
          }
        }
      }
    }

    &__link {
      &:hover {
        border-bottom-color: #fff !important;

        .certificates {
          &__item-value {
            color: #fff;
          }

          &__arrow {
            opacity: 1;
          }
        }
      }
    }

    &__arrow {
      flex: 0 0 var(--grid-column1);
      justify-content: flex-end;
      transition: opacity var(--default-timing) var(--default-transition-function);
      opacity: 0;
    }

    &__picture {
      display: flex;
      width: 100%;
      height: 100%;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__images-list {
      display: flex;
      position: fixed;
      pointer-events: none;
      top: 50%;
      left: 50%;
      width: calc((156 / 1920) * 100vw);
      height: calc((221 / 1920) * 100vw);
      max-width: 208px;
      max-height: 294px;
      z-index: 5;
      opacity: 0;
      transition: opacity .3s var(--default-transition-function);

      &._visible {
        opacity: 1;
      }
    }

    &__image-wrap {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      opacity: 0;
      transition: opacity .3s var(--default-transition-function) .3s;

      &._visible {
        opacity: 1;
        transition-delay: 0s;
      }
    }

    &__picture {
      position: relative;
      overflow: hidden;
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(var(--grid-column1) + var(--grid-gap));
      height: calc((var(--grid-column1) + var(--grid-gap)) * 221 / 156);
    }
  }
}

@include respond('s-large') {
  .certificates {
    &__item-value {
      &_name {
        flex: 0 0 var(--grid-column5);
      }

      &_subtitle,
      &_file {
        flex: 0 0 var(--grid-column1);
      }
    }
  }
}

@include respond-up('medium') {
  .certificates {
    &__link {
      padding: 13px 0 15px;
    }
  }
}

@include respond('medium') {
  .certificates {
    &__wrap {
      &.grid-block {
        grid-row-gap: 15px;
      }
    }

    &__link {
      position: relative;
    }

    &__item-value {
      &_name {
        flex: 0 0 var(--grid-column4);
      }

      &_subtitle {
        flex: 0 0 var(--grid-column1);
      }

      &_file {
        flex: 0 0 var(--grid-column1);
      }
    }

    &__arrow {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
  }
}

@include respond-down('medium') {
  .certificates {
    &__wrap {
      grid-template-columns: repeat(6, 1fr);
    }

    &__title,
    &__list-wrap {
      grid-column: 1/7;
    }

    &__title {
      grid-row: 1;
    }

    &__list-wrap {
      grid-row: 2;
    }

    &__item {
      &:first-child {
        .certificates {
          &__link {
            border-top: 1px solid #fff;
          }
        }
      }
    }

    &__images-list {
      display: none;
    }
  }
}

@include respond-down('small') {
  .certificates {
    &__link {
      padding: 9px 0;
      align-items: flex-start;
    }

    &__wrap {
      &.grid-block {
        grid-row-gap: 10px;
      }
    }

    &__item-value {
      &_name {
        flex: 0 0 var(--grid-column4);
      }

      &_subtitle {
        flex: 0 0 var(--grid-column1);
      }

      &_file {
        display: none;
      }
    }

    &__arrow {
      flex: 0 0 var(--grid-column1);
      justify-content: flex-end;
    }
  }
}