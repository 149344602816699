.mass-media {
  background: #fff;

  &__title {
    width: 100%;
  }

  &__grid {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__pagination-wrap {
    width: 100%;
  }

  &__title-container {
    grid-column: 1/5;
  }

  &__list {
    display: grid;
    grid-column-gap: var(--grid-gap);
    grid-row-gap: 0;
  }

  &__item {
    grid-column: span 1;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__link {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__link-titles {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__link-heading {
    display: block;
  }

  &__link-title {
    line-height: 125%;
    position: relative;
    padding-bottom: 1px;
    border-bottom: 2px solid var(--primary-color);
  }

  &__link-image {
    display: none;
  }

  &.need-animation {
    .animation-mask__line span {
      transform: translate3d(0, 110%, 0);
    }
  }
}

@include respond-up('large') {
  .mass-media {
    &__title {
      margin-top: 59px;
    }

    &__link-heading {
      margin-bottom: 65px;
    }

    &__pagination-wrap {
      padding: 73px 0 62px;
    }
  }
}

@include respond-up('s-large') {
  .mass-media {
    padding-top: 60px;

    &__grid {
      grid-template-columns: repeat(12, 1fr);
      border-top: 1px solid var(--primary-color);
    }

    &__list-container {
      grid-column: 5/13;
    }

    &__title-container {
      padding-top: 15px;
    }

    &__list {
      grid-template-columns: repeat(2, 1fr);

      &:hover {
        .mass-media {
          &__link-heading,
          &__link-title {
            color: var(--primary-color-30);
            border-color: var(--primary-color-30);
          }
        }
      }
    }

    &__item {
      position: relative;

      &:nth-child(n+3) {
        border-top: 1px solid var(--primary-color);
      }

      &:hover {
        .mass-media {
          &__link-heading,
          &__link-title {
            color: var(--primary-color);
            border-color: var(--primary-color);
          }

          &__image-wrap {
            opacity: 1;
          }
        }
      }
    }

    &__link {
      padding: 15px 0 73px;

      &:hover {
        .h5_underline {
          &::before {
            transform: scaleX(1);
          }
        }
      }
    }

    &__link-titles {
      width: var(--grid-column3);
    }

    &__link-heading {
      transition: color .3s var(--default-transition-function);
    }

    &__link-title {
      transition: color, border-color;
      transition-duration: .3s;
      transition-timing-function: var(--default-transition-function);
    }

    &__image-wrap {
      display: flex;
      position: absolute;
      bottom: calc(var(--grid-gap) * -1);
      right: calc(var(--grid-gap) * -1);
      width: calc((200 / 1920) * 100vw);
      height: calc((200 / 1920) * 100vw);
      max-width: 285px;
      max-height: 285px;
      overflow: hidden;
      opacity: 0;
      transition: opacity .3s var(--default-transition-function);

      &._visible {
        opacity: 1;
      }
    }
  }
}

@include respond('s-large') {
  .mass-media {
    &__title {
      margin-top: 43px;
    }

    &__link-heading {
      margin-bottom: 47px;
    }

    &__pagination-wrap {
      padding: 52px 0 62px;
    }
  }
}

@include respond('medium') {
  .mass-media {
    padding-top: 40px;

    &__grid {
      grid-row-gap: 33px;
    }

    &__link {
      padding: 15px 0 54px;
    }

    &__link-heading {
      margin-bottom: 26px;
    }

    &__pagination-wrap {
      padding: 72px 0 42px;
    }

    &__link-image {
      flex: 0 0 var(--grid-column);
      height: var(--grid-column);
    }
  }
}

@include respond-down('medium') {
  .mass-media {
    &__grid {
      grid-template-columns: repeat(6, 1fr);
    }

    &__list-container {
      grid-column: 1/7;
    }

    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      border-top: 1px solid var(--primary-color);
    }

    &__link {
      justify-content: space-between;
    }

    &__small-title {
      display: none;
    }

    &__link-titles {
      flex: 0 1 var(--grid-column4);
    }

    &__link-image {
      display: block;
      padding-top: 5px;
    }

    &__image-wrap {
      display: none;
    }
  }
}

@include respond-down('small') {
  .mass-media {
    padding-top: 30px;

    &__grid {
      grid-row-gap: 26px;
    }

    &__link {
      padding: 9px 0 35px;
    }

    &__link-heading {
      margin-bottom: 8px;
    }

    &__pagination-wrap {
      padding: 30px 0 32px;
    }

    &__link-image {
      flex: 0 0 var(--grid-column2);
      height: var(--grid-column2);
    }
  }
}