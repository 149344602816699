.about-bottom {
  &__list {
    display: grid;
    grid-column-gap: var(--grid-gap);
    grid-row-gap: 0;

    &.need-animation {
      .animation-mask__line span {
        transform: translate3d(0, 110%, 0);
      }
    }
  }

  &__item {
    &_text {
      display: flex;
      flex-direction: column;
    }

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        .animation-mask__line span {
          transition-delay: calc(((#{$i} - 1) * 0.1s));
        }
      }
    }
  }

  &__item-title {
    width: 100%;
  }

  &__item-title,
  &__item-description,
  &__description {
    color: #fff;
  }

  &__description_bottom {
    margin-top: 24px;
  }
}

@include respond-up('large') {
  .about-bottom {
    padding-top: 64px;

    &__item {
      &:nth-child(odd) {
        grid-column: 1/3;
      }

      &:nth-child(even) {
        grid-column: 5/7;
      }

      &:nth-child(3) {
        grid-column: 5/7;
      }

      &:nth-child(4) {
        grid-column: 1/3;
      }

      &_description {
        grid-column: 9/12 !important;
      }
    }
  }
}

@include respond-up('s-large') {
  .about-bottom {
    &__list {
      grid-template-columns: repeat(12, 1fr);
    }

    &__item {
      &:nth-child(n+3) {
        margin-top: 60px;
      }

      &_description {
        grid-row: 1/5;
        margin-top: unset !important;
      }
    }
  }
}

@include respond('s-large') {
  .about-bottom {
    &__item {
      &:nth-child(odd) {
        grid-column: 1/4;
      }

      &:nth-child(even) {
        grid-column: 5/8;
      }

      &:nth-child(3) {
        grid-column: 5/8;
      }

      &:nth-child(4) {
        grid-column: 1/4;
      }

      &_description {
        grid-column: 9/13 !important;
      }
    }
  }
}

@include respond-down('s-large') {
  .about-bottom {
    padding-top: 48px;
  }
}

@include respond-up('medium') {
  .about-bottom {
    &__item-description {
      margin-top: 18px;
    }
  }
}

@include respond('medium') {
  .about-bottom {
    &__item {
      &:nth-child(n+3) {
        margin-top: 48px;
      }

      &:nth-child(odd) {
        grid-column: 1/3;
      }

      &:nth-child(even) {
        grid-column: 4/6;
      }

      &_description {
        grid-column: 4/7 !important;
      }
    }
  }
}

@include respond-down('medium') {
  .about-bottom {
    &__list {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}

@include respond-down('small') {
  .about-bottom {
    padding-top: 33px;

    &__item {
      grid-column: 1/7;

      &_text {
        flex-direction: row;
      }

      &:nth-child(n+2) {
        margin-top: 19px;
      }

      &_description {
        margin-top: 41px !important;
      }
    }

    &__item-title {
      flex: 0 0 var(--grid-column2);
    }

    &__item-description {
      flex: 0 1 100%;
      margin-left: var(--grid-gap);
    }

    &__description_bottom {
      margin-top: 21px;
    }
  }
}