.sticky-phone {
  position: fixed;
  z-index: 100;
  top: 30%;
  transform: translate3d(0, -50%, 0);
  left: calc(100% - (60px + var(--grid-spacer)));
  overflow: hidden;
  pointer-events: none;

  &._visible {
    pointer-events: auto;

    .button__inner {
      transform: translate3d(0, 0, 0);
    }
  }

  .button__inner {
    transform: translate3d(100%, 0, 0);
  }
}

@include respond-down('medium') {
  .sticky-phone {
    display: none;
  }
}