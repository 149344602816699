@include respond-up('s-large') {
  .recall-modal {
    .modal-form {
      &__subtitle {
        margin-top: 56px;
      }

      &__field_phone {
        margin-top: 40px;
      }
    }
  }
}

@include respond-up('medium') {
  .recall-modal {
    .modal-form {
      &__subtitle {
        width: var(--grid-column3);
      }

      &__form-wrap {
        margin-top: 44px;
      }

      &__button-wrap {
        margin-top: 40px;
      }
    }
  }
}

@include respond('medium') {
  .recall-modal {
    .modal-form {
      &__title {
        width: var(--grid-column3);
      }

      &__subtitle {
        margin-top: 38px;
      }

      &__fields {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      &__field {
        flex: 0 0 var(--grid-column3);
      }
    }
  }
}

@include respond-down('small') {
  .recall-modal {
    .modal-form {
      &__subtitle {
        margin-top: 21px;
      }

      &__form-wrap {
        margin-top: 30px;
      }

      &__field_phone {
        margin-top: 20px;
      }

      &__button-wrap {
        margin-top: 20px;
      }
    }
  }
}