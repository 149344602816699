.modal-project-slider {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(&._opened) {
    .modal-project-slider {
      &__main-item {
        &._active {
          .modal-project-slider {
            &__title-inner {
              span {
                transform: translate3d(0, 110%, 0);
                transition-delay: .6s;
                transition-duration: 0s;
              }
            }

            &__image {
              opacity: 0;
              transform: scale3d(1.1, 1.1, 1);
              transition-delay: .6s;
              transition-duration: 0s;
            }

            &__about-wrap,
            &__review-wrap,
            &__form-wrap {
              opacity: 0;
              transition-delay: .6s;
              transition-duration: 0s;
            }
          }
        }
      }
    }
  }

  &__main {
    flex: 0 1 100%;
  }

  &__nav-wrap {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--primary-color);
    background: #fff;
    pointer-events: none;

    &._active {
      pointer-events: auto;
    }
  }

  &__main-list {
    position: relative;
    height: 100%;
  }

  &__main-item {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    display: block;
    height: 100%;
    width: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &._active {
      z-index: 10;

      .modal-project-slider {
        &__title-inner {
          span {
            transform: translate3d(0, 0, 0);
            transition-duration: 1s;
          }

          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              span {
                transition-delay: calc(.3s + ((#{$i} - 1) * .1s));
              }
            }
          }
        }

        &__image {
          opacity: 1;
          transform: scale3d(1, 1, 1);
          transition-delay: .3s;
        }

        &__about-wrap,
        &__review-wrap,
        &__form-wrap {
          opacity: 1;
          transition-delay: .3s;
        }
      }
    }

    &._out-next-act {
      z-index: 15;
      pointer-events: none;

      .modal-project-slider {
        &__title {
          opacity: 0;
        }

        &__title-inner {
          span {
            transform: translate3d(0, 0, 0);
          }
        }

        &__image {
          opacity: 0;
          transform: scale3d(1, 1, 1);
          transition-duration: .3s;
        }
      }
    }
  }

  &__title {
    width: var(--grid-column6);
    transition: opacity .3s var(--default-transition-function);
  }

  &__title-inner {
    display: block;
    overflow: hidden;

    span {
      transition: transform 0s var(--default-transition-function);
      transform: translate3d(0, 110%, 0);
    }
  }

  &__images-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--grid-gap);
  }

  &__image-wrap {
    display: flex;

    &_wide {
      grid-column: span 2;
    }

    &_half {
      grid-column: span 1;
    }
  }

  &__about-wrap,
  &__form-wrap {
    transition: opacity .3s var(--default-transition-function);
    opacity: 0;
  }

  &__about-list {
    display: flex;
    flex-direction: column;
  }

  &__about-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--primary-color);

    &:last-child {
      border-bottom: 1px solid var(--primary-color);
    }
  }

  &__review-wrap {
    display: flex;
    transition: opacity .3s var(--default-transition-function);
    opacity: 0;
  }

  &__review-top {
    display: flex;
    align-items: center;
  }

  &__review-sender-info {
    margin-left: var(--grid-gap);
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: var(--default-transition-function);
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1);
  }
}

@include respond-up('large') {
  .modal-project-slider {
    &__image-wrap {
      &_review {
        flex: 0 0 auto;
      }
    }

    &__review-sender-info {
      flex: 0 1 100%;
    }

    &__review-job-position {
      margin-top: 20px;
    }

    &__review-text {
      margin-top: 53px;
      width: var(--grid-column3);
    }
  }
}

@include respond-up('s-large') {
  .modal-project-slider {
    &__main-item-inner {
      padding: 44px 0 100px;
    }

    &__images-wrap {
      margin-top: 53px;
    }

    &__image-wrap {
      &_wide {
        height: calc(var(--grid-column8) * (660 / 1217));
      }

      &_half {
        height: calc(var(--grid-column4) * (541 / 594));
      }

      &_review {
        height: var(--grid-column1);
        width: var(--grid-column1);
      }
    }

    &__about-item-title,
    &__about-item-value,
    &__review-title {
      flex: 0 0 var(--grid-column4);
    }

    &__review-wrap {
      grid-template-columns: repeat(8, var(--grid-column));
    }

    &__review {
      flex: 0 0 var(--grid-column4);
      padding-top: 11px;
    }

    &__review-top {
      width: var(--grid-column3);
    }

    &__form-wrap {
      margin-top: 134px;
    }
  }
}

@include respond('s-large') {
  .modal-project-slider {
    &__image-wrap {
      &_review {
        height: calc(var(--grid-column2) - 49px);
        width: calc(var(--grid-column2) - 49px);
      }
    }

    &__review-text {
      width: var(--grid-column4);
    }
  }
}

@include respond-between('medium', 's-large') {
  .modal-project-slider {
    &__review-top {
      flex-direction: column;
      align-items: flex-start;
    }

    &__review-sender-info {
      margin-top: 23px;
      margin-left: unset;
    }

    &__review-text {
      margin-top: 28px;
    }
  }
}


@include respond-down('s-large') {
  .modal-project-slider {
    &__review-job-position {
      margin-top: 11px;
    }
  }
}

@include respond-up('medium') {
  .modal-project-slider {
    &__prev,
    &__next {
      padding: 32px var(--grid-spacer);
      margin: 0 calc(var(--grid-spacer) * -1);
    }

    &__about-list {
      margin-top: 35px;
    }

    &__about-item {
      padding: 13px 0 15px;
    }

    &__about-wrap {
      margin-top: 69px;
    }

    &__review-wrap {
      justify-content: space-between;
      margin-top: 69px;
    }
  }
}

@include respond('medium') {
  .modal-project-slider {
    &__main-item-inner {
      padding: 38px 0 80px;
    }

    &__title {
      width: var(--grid-column5);
    }

    &__images-wrap {
      margin-top: 43px;
    }

    &__image-wrap {
      &_review {
        height: var(--grid-column1);
        width: var(--grid-column1);
      }
    }

    &__about-wrap,
    &__review-wrap {
      margin-top: 51px;
    }

    &__review-title {
      flex: 0 0 var(--grid-column3);
    }

    &__review {
      flex: 0 0 var(--grid-column3);
      padding-top: 9px;
    }

    &__review-top {
      width: var(--grid-column2);
    }

    &__form-wrap {
      margin-top: 86px;
    }
  }
}

@include respond-down('medium') {
  .modal-project-slider {
    &__about-item-title,
    &__about-item-value {
      flex: 0 0 var(--grid-column3);
    }

    &__image-wrap {
      &_wide {
        height: calc(var(--grid-column6) * (420 / 774));
      }

      &_half {
        height: calc(var(--grid-column3) * (343 / 377));
      }
    }
  }
}

@include respond-down('small') {
  .modal-project-slider {
    &__main-item-inner {
      padding: 23px 0 46px;
    }

    &__prev,
    &__next {
      padding: 20px var(--grid-spacer);
      margin: 0 calc(var(--grid-spacer) * -1);
    }

    &__images-wrap {
      margin-top: 26px;
    }

    &__image-wrap {
      &_review {
        height: var(--grid-column2);
        width: var(--grid-column2);
        flex: 0 0 auto;
      }
    }

    &__about-list {
      margin-top: 21px;
    }

    &__about-item {
      padding: 9px 0;
    }

    &__about-wrap {
      margin-top: 34px;
    }

    &__review-wrap {
      flex-direction: column;
      margin-top: 34px;
    }

    &__review {
      margin-top: 26px;
    }

    &__review-text {
      margin-top: 25px;
    }

    &__form-wrap {
      margin-top: 60px;
    }
  }
}