@include respond-up('s-large') {
  .review-modal {
    .modal-form {
      &__field {
        &:not(:first-child) {
          margin-top: 53px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .review-modal {
    .modal-form {
      &__fields {
        margin-top: 56px;
      }

      &__button-wrap {
        margin-top: 40px;
      }
    }
  }
}

@include respond('medium') {
  .review-modal {
    .modal-form {
      &__fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: var(--grid-gap);
      }

      &__field {
        &_name {
          grid-column: 1/2;
        }

        &_email {
          grid-column: 2/3;
        }

        &_text,
        &_files {
          margin-top: 53px;
          grid-column: 1/3;
        }
      }
    }
  }
}

@include respond-down('small') {
  .review-modal {
    .modal-form {
      &__fields {
        margin-top: 41px;
      }

      &__field {
        &:not(:first-child) {
          margin-top: 30px;
        }
      }

      &__button-wrap {
        margin-top: 20px;
      }
    }
  }
}