.text-stretched-block {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__title {
    text-align: center;
    color: #fff;
    transition: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: var(--default-transition-function);
    transform: scaleX(1) translate3d(var(--lp-translate-h5-half), 0, 0);
  }

  &__author-wrap {
    display: flex;
    justify-content: center;
    transition: opacity 1s var(--default-transition-function) .2s;
  }

  &__author {
    color: #fff;
    margin-left: 25px;
  }

  &__picture {
    display: flex;
  }

  &__image {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &.need-animation {
    .text-stretched-block {
      &__title {
        transform: scaleX(0.83) translate3d(var(--lp-translate-h5-half), 0, 0);
        opacity: 0;
      }

      &__author-wrap {
        opacity: 0;
      }
    }
  }
}

@include respond-up("large") {
  .text-stretched-block {
    padding: 187px calc(var(--grid-spacer) + var(--grid-column2) + var(--grid-gap)) 190px;

    &__author-wrap {
      margin-top: 64px;
    }
  }
}

@include respond("s-large") {
  .text-stretched-block {
    padding: 139px calc(var(--grid-spacer) + var(--grid-column) + var(--grid-gap)) 142px;

    &__author-wrap {
      margin-top: 62px;
    }
  }
}

@include respond-up("medium") {
  .text-stretched-block {
    &__picture {
      width: 152px;
      height: 46px;
    }

    &__author-wrap {
      align-items: center;
    }

    &__author {
      max-width: var(--grid-column2);
    }
  }
}

@include respond("medium") {
  .text-stretched-block {
    padding: 121px var(--grid-spacer) 123px;

    &__author-wrap {
      margin-top: 43px;
    }
  }
}

@include respond-down("small") {
  .text-stretched-block {
    padding: 64px var(--grid-spacer);

    &__author-wrap {
      margin-top: 20px;
    }

    &__author {
      margin-left: 10px;
      max-width: var(--grid-column3);
    }

    &__picture {
      width: 92px;
      height: 28px;
    }
  }
}
