.layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100); //true-vh
  background-color: var(--primary-color);
}

@include respond-up('medium') {
  :root {
    --header-height: 80px;
  }

  .layout {
    --header-height: 80px;
  }
}

@include respond-down('small') {
  :root {
    --header-height: 48px;
  }

  .layout {
    --header-height: 48px;
  }
}