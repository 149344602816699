.product-slider {
  background-color: #fff;

  &__meta {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  &__item {
    background-color: #fff;

    &:nth-child(even) {
      background-color: var(--grey-color);
    }
  }

  &__item-content {
    display: grid;
    grid-column: var(--grid-gap);
    grid-row-gap: 0;
  }

  &__picture-wrap {
    display: flex;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__title-right {
    text-align: right;
    transform: translate3d(var(--lp-translate-h5), 0, 0);
  }

  &__link-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &__link {
    padding: 20px;
    margin: -20px;
  }

  &__title-left,
  &__title-right {
    overflow: hidden;
  }
}

@include respond-up('large') {
  .product-slider {
    &__item-content {
      padding: 47px 0 55px;
    }

    &__picture-wrap {
      width: calc(var(--grid-column4) - 38px * 2);
      height: calc((var(--grid-column4) - 38px * 2) * (611 / 518));
    }
  }
}

@include respond-up('s-large') {
  .product-slider {
    position: relative;
    height: calc(100vh - var(--header-height));

    &__inner {
      position: sticky;
      top: var(--header-height);
      z-index: 5;
      height: calc(100vh - var(--header-height));
      overflow: hidden;
    }

    &__list {
      position: absolute;
      top: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
    }

    &__item,
    &__text-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100vh - var(--header-height));
    }

    &__item {
      overflow: hidden;
    }

    &__text-item {
      .product-slider {
        &__title-inner {
          transform: translate3d(0, 100%, 0);
          animation: product-title-out .4s var(--default-transition-function) forwards;
        }

        &__description {
          opacity: 0;
          animation: product-description-out .3s var(--default-transition-function) forwards;
        }

        &__link-wrap {
          opacity: 0;
        }
      }

      &._active {
        .product-slider {
          &__title-inner {
            animation: product-title-in .4s var(--default-transition-function) .4s forwards;
          }

          &__description {
            animation: product-description-in .7s var(--default-transition-function) .4s forwards;
          }

          &__link-wrap {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }

    &__item-inner {
      position: absolute;
      left: 0;
      width: 100%;
    }

    &__item-content {
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: auto 1fr;
      height: calc(100vh - var(--header-height));
    }

    &__picture-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      transition: transform 0s var(--default-transition-function);
    }

    &__title-left {
      grid-column: 1/5;
    }

    &__title-right {
      grid-column: 9/13;
    }

    &__description {
      margin-top: auto;
      padding-bottom: 65px;
    }

    &__title-left,
    &__title-right,
    &__description,
    &__link-wrap {
      &_mobile {
        display: none;
      }
    }

    &__link-wrap {
      grid-column: 1/3;
      margin-top: auto;
      grid-row: 3;
    }

    &__text-list {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      pointer-events: none;
    }

    &__pagination {
      display: flex;
      align-items: center;
      position: absolute;
      z-index: 10;
      bottom: 55px;
      right: var(--grid-spacer);
      pointer-events: none;
    }

    &__current-list {
      position: relative;
      display: flex;
      overflow: hidden;
      width: 19px;
      height: 21px;
    }

    &__current-item {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      width: 19px;
      height: 21px;
      transform: translate3d(0, 100%, 0);
      animation: product-title-out .3s var(--default-transition-function) forwards;

      &._active {
        animation: product-title-in .3s var(--default-transition-function) .3s forwards;
      }
    }

    &__progress-bar {
      position: relative;
      width: 99px;
      height: 2px;
      background-color: var(--primary-color-20);
      margin: 0 15px;
    }

    &__progress-bar-fill {
      position: absolute;
      width: 0;
      height: 100%;
      background-color: var(--primary-color);
    }
  }
}

@include respond('s-large') {
  .product-slider {
    &__item-content {
      padding: 60px 0 55px;
    }

    &__picture-wrap {
      width: calc(var(--grid-column4) - 17px * 2);
      height: calc((var(--grid-column4) - 17px * 2) * (611 / 518));
    }
  }
}

@include respond-up('medium') {
  .product-slider {
    &__description {
      grid-column: 1/4;
    }
  }
}

@include respond('medium') {
  .product-slider {
    &__item-content {
      padding: 31px 0 35px;
    }

    &__picture-wrap {
      margin-top: 53px;
    }

    &__picture {
      width: calc((386 / 834) * 100vw);
      height: calc((455 / 834) * 100vw);
    }

    &__description {
      margin-top: 83px;
    }

    &__link-wrap {
      grid-column: 4/7;
      justify-content: flex-end;
      margin-top: 83px;
      padding-bottom: 5px;
    }
  }
}

@include respond-down('medium') {
  .product-slider {
    &__item-content {
      grid-template-columns: repeat(6, 1fr);
    }

    &__title-left {
      grid-column: 1/4;
    }

    &__title-right {
      grid-column: 4/7;
    }

    &__picture-wrap {
      grid-column: 1/7;
      align-items: center;
      justify-content: center;
    }

    &__text-list,
    &__pagination {
      display: none;
    }
  }
}

@include respond-down('small') {
  .product-slider {
    &__item-content {
      padding: 23px 0 30px;
    }

    &__picture-wrap {
      margin-top: 25px;
    }

    &__picture {
      width: calc((234 / 375) * 100vw);
      height: calc((275 / 375) * 100vw);
    }

    &__description {
      grid-column: 1/7;
      margin-top: 45px;
    }

    &__link-wrap {
      grid-column: 1/7;
      margin-top: 35px;
    }
  }
}

@keyframes product-description-in {
  from {
    opacity: 0;
    transform: translate3d(80px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes product-description-out {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-80px, 0, 0);
  }
}

@keyframes product-title-in {
  from {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes product-title-out {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, -100%, 0);
  }
}