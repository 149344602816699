.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    color: #fff;
    text-align: center;
    font-family: var(--h-font);
    font-size: 400px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
  }

  &__description {
    color: #fff;
  }

  &__button {
    border: 1px solid #fff;
  }
}

@include respond-up('s-large') {
  .error-page {
    padding: calc(99px + var(--header-height)) 0;
  }
}

@include respond-up('medium') {
  .error-page {
    &__description {
      margin-top: 40px;
    }

    &__button {
      margin-top: 60px;
      width: 368px;
    }
  }
}

@include respond('medium') {
  .error-page {
    padding: calc(114px + var(--header-height)) 0;

    &__title {
      font-size: 300px;
    }
  }
}

@include respond-down('small') {
  .error-page {
    padding: calc(146px + var(--header-height)) 0;

    &__title {
      font-size: 150px;
    }

    &__description {
      margin-top: 20px;
    }

    &__button {
      margin-top: 30px;
      width: 222px;
    }
  }
}