.modal-product-slider {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(&._opened) {
    .modal-product-slider {
      &__main-item {
        &._active {
          .modal-product-slider {
            &__main-item-title {
              opacity: 0;
              transform: translate3d(var(--modal-product-title-translate), 0, 0) scaleX(0.83);
              transition-delay: .6s;
              transition-duration: 0s;
            }

            &__picture-wrap {
              opacity: 0;
              transform: scale3d(1.1, 1.1, 1);
              transition-delay: .6s;
              transition-duration: 0s;
            }

            &__description {
              opacity: 0;
              transform: translate3d(80px, 0, 0);
              transition-delay: .6s;
              transition-duration: 0s;
            }
          }
        }
      }
    }
  }

  &__thumbs {
    border-bottom: 1px solid var(--primary-color);
    flex: 0 0 auto;
  }

  &__thumbs-list {
    display: flex;
  }

  &__thumbs-item {
    &._active {
      .modal-product-slider__thumbs-title {
        color: var(--primary-color);
      }
    }
  }

  &__thumbs-link {
    display: flex;
  }

  &__thumbs-title {
    color: var(--primary-color-30);
    transition: color .4s var(--default-transition-function);

    &::before {
      transform: scaleX(0);
    }
  }

  &__main {
    flex: 0 1 100%;
  }

  &__main-list {
    position: relative;
    height: 100%;
    margin: 0 calc(var(--grid-spacer) * -1);
  }

  &__main-item {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    display: block;
    height: 100%;
    width: 100%;
    background-color: #fff;
    transition: opacity .3s var(--default-transition-function);
    opacity: 0;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    &._active {
      z-index: 10;
      opacity: 1;
      transition-duration: .1s;

      .modal-product-slider {
        &__main-item-title {
          opacity: 1;
          transition-duration: 1.3s;
          transform: translate3d(var(--modal-product-title-translate), 0, 0) scaleX(1);
        }

        &__description {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition-duration: .7s;
          transition-delay: .2s;
        }

        &__picture-wrap {
          opacity: 1;
          transform: scale3d(1, 1, 1);
          transition-duration: .9s;
          transition-delay: .2s;
        }

        &__threshold,
        &__characteristics,
        &__bottom-description-wrap,
        &__form-wrap {
          opacity: 1;
          transition-delay: .1s;
        }
      }
    }

    &._out-next-act {
      z-index: 15;
      pointer-events: none;

      .modal-product-slider {
        &__main-item-title {
          opacity: 0;
          transition-duration: .3s;
          transform: translate3d(var(--modal-product-title-translate), 0, 0) scaleX(1);
        }

        &__description {
          transform: translate3d(-80px, 0, 0);
          opacity: 0;
          transition-duration: .4s;
        }

        &__picture-wrap {
          opacity: 0;
          transform: scale3d(1, 1, 1);
          transition-duration: .3s;
        }
      }
    }
  }

  &__threshold,
  &__characteristics,
  &__bottom-description-wrap,
  &__form-wrap {
    opacity: 0;
    transition: opacity .3s var(--default-transition-function);
  }

  &__main-item-title-wrap,
  &__threshold,
  &__characteristics,
  &__bottom-description-wrap,
  &__form-wrap {
    padding: 0 var(--grid-spacer);
  }

  &__main-item-title-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__main-item-title {
    text-align: center;
    opacity: 0;
    transition: opacity, transform;
    transition-duration: 0s;
    transition-timing-function: var(--default-transition-function);
    transform: translate3d(var(--modal-product-title-translate), 0, 0) scaleX(0.83);
  }

  &__main-image-container {
    display: grid;
    grid-column-gap: var(--grid-gap);
    background-color: var(--primary-color);
  }

  &__picture-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity, transform;
    transition-timing-function: var(--default-transition-function);
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1);
  }

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__description {
    display: flex;
    color: #fff;
    transition: opacity, transform;
    transition-duration: .0s;
    transition-timing-function: var(--default-transition-function);
    transform: translate3d(80px, 0, 0);
    opacity: 0;
  }

  &__characteristics-item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: var(--grid-gap);
    border-bottom: 1px solid var(--primary-color);

    &:first-child {
      border-top: 1px solid var(--primary-color);
    }
  }

  &__characteristics-name,
  &__characteristics-value {
    grid-column: span 1;
  }

  &__bottom-description-wrap {
    display: flex;
  }

  &__bottom-description-list {
    display: flex;
    flex-direction: column;
  }

  &__threshold-images {
    display: flex;
  }

  &__threshold-image {
    display: flex;

    .modal-product-slider__picture {
      width: 100%;
      height: 100%;
      max-width: unset;
      max-height: unset;
    }
  }
}

@include respond-up('large') {
  .modal-product-slider {
    &__main-item-title {
      --modal-product-title-translate: calc((32 / 1920) * 100vw);

      &.h3.h3_stretched {
        letter-spacing: calc((64 / 1920) * 100vw);
      }
    }
  }
}

@include respond-up('s-large') {
  .modal-product-slider {
    &__thumbs-link {
      &:hover {
        .modal-product-slider__thumbs-title {
          color: var(--primary-color);

          &::before {
            transform: scaleX(1);
            transform-origin: left center;
          }
        }
      }
    }

    &__thumbs-title {
      &::before {
        transform-origin: center right;
      }
    }

    &__main-image-container {
      grid-template-columns: repeat(8, var(--grid-column));
      margin-top: 25px;
      padding: 72px var(--grid-spacer);
    }

    &__picture-wrap {
      grid-column: 1/5;
    }

    &__picture {
      width: calc((362 / 1920) * 100vw);
      height: calc((427 / 1920) * 100vw);
      max-width: 362px;
      max-height: 427px;
    }

    &__description {
      align-items: center;
      grid-column: 5/8;
    }

    &__main-item-inner {
      padding: 17px 0 100px;
    }

    &__threshold,
    &__characteristics {
      margin-top: 69px;
    }

    &__form-wrap {
      margin-top: 134px;
    }

    &__bottom-description-list {
      padding-right: calc(var(--grid-column) + var(--grid-gap));
    }

    &__threshold-image {
      &_big {
        width: calc((763 / 1920) * 100vw);
        height: calc((578 / 1920) * 100vw);
        max-width: 763px;
        max-height: 578px;
      }

      &_small {
        width: calc((348 / 1920) * 100vw);
        height: calc((578 / 1920) * 100vw);
        max-width: 348px;
        max-height: 578px;
      }

      &:last-child {
        --threshold-image-ml: min(106px, calc((106 / 1920) * 100vw));
        margin-left: var(--threshold-image-ml);
      }
    }
  }
}

@include respond('s-large') {
  .modal-product-slider {
    &__main-item-title {
      --modal-product-title-translate: calc((22 / 1440) * 100vw);

      &.h3.h3_stretched {
        letter-spacing: calc((44 / 1440) * 100vw);
      }
    }
  }
}

@include respond-up('medium') {
  .modal-product-slider {
    &__thumbs-list {
      margin: 0 -15px;
    }

    &__thumbs-link {
      padding: 22px 15px;
    }

    &__characteristics-list {
      margin-top: 35px;
    }

    &__characteristics-item {
      padding: 13px 0 15px;
    }

    &__bottom-description-wrap {
      justify-content: flex-end;
      margin-top: 43px;
    }

    &__bottom-description {
      width: var(--grid-column3);

      &:last-child {
        margin-top: 18px;
      }
    }

    &__threshold-images {
      margin-top: 25px;
    }
  }
}

@include respond('medium') {
  .modal-product-slider {
    &__main-image-container {
      padding: 70px var(--grid-spacer) 35px;
      grid-row-gap: 83px;
      margin-top: 20px;
    }

    &__picture-wrap {
      grid-column: 2/6;
    }

    &__picture {
      width: calc((386 / 834) * 100vw);
      height: calc((455 / 834) * 100vw);
    }

    &__description {
      grid-column: 1/4;
    }

    &__main-item-inner {
      padding: 14px 0 80px;
    }

    &__threshold,
    &__characteristics {
      margin-top: 51px;
    }

    &__form-wrap {
      margin-top: 86px;
    }

    &__main-item-title {
      --modal-product-title-translate: calc((18 / 834) * 100vw);

      &.h3.h3_stretched {
        letter-spacing: calc((38 / 834) * 100vw);
      }
    }

    &__threshold-image {
      &_big {
        width: calc((485 / 834) * 100vw);
        height: calc((368 / 834) * 100vw);
      }

      &_small {
        width: calc((221 / 834) * 100vw);
        height: calc((368 / 834) * 100vw);
      }

      &:last-child {
        --threshold-image-ml: min(68px, calc((68 / 834) * 100vw));
        margin-left: var(--threshold-image-ml);
      }
    }
  }
}

@include respond-down('medium') {
  .modal-product-slider {
    &__main-image-container {
      grid-template-columns: repeat(6, var(--grid-column));
    }
  }
}

@include respond-down('small') {
  .modal-product-slider {
    &__thumbs {
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: 5;
        top: 0;
        width: 20px;
        height: 100%;
      }

      &::before {
        right: 29px;
        background: linear-gradient(-90deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0));
      }

      &::after {
        left: -12px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0));
      }
    }

    &__thumbs-list {
      margin: 0 -10px;
      overflow-x: scroll;
      max-width: calc(100vw - var(--grid-spacer) * 2 - 20px);

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__thumbs-link {
      padding: 10px;
    }

    &__main-image-container {
      padding: 40px var(--grid-spacer) 35px;
      grid-row-gap: 45px;
      margin-top: 14px;
    }

    &__picture-wrap,
    &__description {
      grid-column: 1/7;
    }

    &__picture {
      width: calc((234 / 375) * 100vw);
      height: calc((275 / 375) * 100vw);
    }

    &__main-item-inner {
      padding: 11px 0 46px;
    }

    &__threshold,
    &__characteristics {
      margin-top: 34px;
    }

    &__characteristics-list {
      margin-top: 20px;
    }

    &__characteristics-item {
      padding: 9px 0;
    }

    &__bottom-description-wrap {
      width: 100%;
      margin-top: 25px;
    }

    &__bottom-description {
      &:last-child {
        margin-top: 10px;
      }
    }

    &__form-wrap {
      margin-top: 60px;
    }

    &__main-item-title {
      --modal-product-title-translate: calc((7 / 375) * 100vw);

      &.h3.h3_stretched {
        letter-spacing: calc((14 / 375) * 100vw);
      }
    }

    &__threshold-images {
      align-items: center;
      flex-direction: column;
      margin-top: 15px;
    }

    &__threshold-image {
      &_big {
        width: 100%;
        height: calc((255 / 375) * 100vw);
      }

      &_small {
        width: calc((154 / 375) * 100vw);
        height: calc((254 / 375) * 100vw);
      }
    }
  }
}