.contacts {
  background-color: #fff;

  &__heading {
    display: flex;
  }

  &__main {
    display: grid;
    grid-gap: var(--grid-gap);
    border-top: 1px solid var(--primary-color);
  }

  &__info-item-title {
    color: var(--primary-color-40);
  }

  &__contents,
  &__content {
    height: 100%;
  }

  &__map {
    height: 100%;
    filter: grayscale(100%);
  }
}

@include respond-up('s-large') {
  .contacts {
    &__heading {
      justify-content: flex-end;
    }

    &__main {
      grid-template-columns: repeat(12, 1fr);
      margin-top: 53px;
      padding-bottom: 40px;
    }

    &__map-container {
      grid-column: 1/9;
      padding-top: 30px;
    }

    &__contents-container {
      grid-column: 9/13;
    }
  }
}

@include respond-up('medium') {
  .contacts {
    &__map-container {
      height: 689px;
    }
  }
}

@include respond('medium') {
  .contacts {
    &__main {
      margin-top: 33px;
      padding-bottom: 30px;
      grid-row-gap: 30px;
    }
  }
}

@include respond-down('medium') {
  .contacts {
    &__main {
      grid-template-columns: repeat(6, 1fr);
    }

    &__map-container {
      grid-column: 1/7;
      grid-row: 2;
    }

    &__contents-container {
      grid-column: 1/7;
      grid-row: 1;
    }
  }
}

@include respond-down('small') {
  .contacts {
    &__main {
      margin-top: 22px;
      grid-row-gap: 18px;
      padding-bottom: 18px;
    }

    &__map-container {
      height: 341px;
    }
  }
}