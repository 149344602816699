.tabs {
  overflow: hidden;

  &__item {
    &._active {
      .tabs {
        &__link-title {
          color: var(--primary-color);
        }
      }
    }
  }

  &__link {
    display: flex;
    white-space: nowrap;
  }

  &__link-title {
    color: var(--primary-color-20);
    transition: color .4s var(--default-transition-function);
  }

  &__delimiter {
    color: var(--primary-color-20);
  }
}

@include respond-up('large') {
  .tabs {
    &__list {
      margin: 0 -21px;
    }

    &__link {
      padding: 0 21px;
    }
  }
}

@include respond-up('s-large') {
  .tabs {
    &__link {
      &:hover {
        .tabs__link-title {
          color: var(--primary-color);
        }
      }
    }
  }
}

@include respond('s-large') {
  .tabs {
    &__list {
      margin: 0 -5px;
    }

    &__link {
      padding: 0 5px;
    }
  }
}

@include respond('medium') {
  .tabs {
    &__list {
      margin: 0 -16px;
    }

    &__link {
      padding: 0 16px;
    }
  }
}

@include respond-down('medium') {
  .tabs {
    position: relative;
    margin: 0 calc(var(--grid-spacer) * -1);

    &__list {
      padding: 0 var(--grid-spacer);
      overflow-x: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .tabs {
    &__list {
      margin: 0 -10px;
    }

    &__link {
      padding: 0 10px;
    }
  }
}