.block-with-heading {
  position: relative;
  z-index: 25;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__title,
  &__subtitle {
    color: #fff;
    text-align: center;
  }

  &__title {
    justify-content: center;
  }

  &__subtitle {
    transition: opacity .4s var(--default-transition-function);
  }

  &__title-inner {
    display: block;
    overflow: hidden;

    span {
      transition: transform 1s var(--default-transition-function);
    }

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        span {
          transition-delay: calc((#{$i} - 1) * 0.2s);
        }
      }
    }
  }

  &.need-animation {
    .block-with-heading {
      &__subtitle {
        opacity: 0;
      }

      &__title-inner {
        span {
          transform: translate3d(0, 110%, 0);
        }
      }
    }
  }
}

@include respond-up("large") {
  .block-with-heading {
    padding: 195px var(--grid-spacer) 190px;

    &__title {
      padding-top: 10px;
    }
  }
}

@include respond("s-large") {
  .block-with-heading {
    padding: 145px var(--grid-spacer) 140px;

    &__title {
      padding-top: 14px;
    }
  }
}

@include respond("medium") {
  .block-with-heading {
    padding: 125px var(--grid-spacer) 121px;

    &__title {
      padding-top: 5px;
    }
  }
}

@include respond-down("small") {
  .block-with-heading {
    padding: 65px var(--grid-spacer) 64px;

    &__title {
      padding-top: 4px;
    }
  }
}
