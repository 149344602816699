.modal-form {
  position: absolute;
  top: 0;
  left: 0;
  height: max-content;
  transition: opacity .5s var(--default-transition-function);

  .default-submit-button {
    display: none;
  }

  &__submit {
    width: 100%;
  }

  &._closing {
    opacity: 0;
  }
}

@include respond-up('s-large') {
  .modal-form {
    width: var(--modal-content-width);
    padding: 34px var(--grid-spacer);
  }
}

@include respond-up('medium') {
  .modal-form {
    &__policy {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .modal-form {
    padding: 38px var(--grid-spacer);
  }
}

@include respond-down('medium') {
  .modal-form {
    width: 100%;
    height: var(--modal-content-height);
  }
}

@include respond-down('small') {
  .modal-form {
    padding: 23px var(--grid-spacer);

    &__policy {
      margin-top: 25px;
    }
  }
}