h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

h1, .h1 {
  line-height: 105%;
}

h2, .h2 {
  line-height: 105%;

  &.h2_stretched {
    line-height: 120%;
  }
}

h3, .h3 {
  line-height: 110%;

  &.h3_stretched {
    line-height: 120%;
  }
}

h4, .h4 {
  line-height: 110%;
}

h5, .h5 {
  line-height: 115%;

  &.h5_underline {
    line-height: 125%;
    position: relative;
    padding-bottom: 1px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: 2px solid var(--primary-color);
      transition: transform .3s var(--default-transition-function);
      transform-origin: left center;
    }
  }

  &.h5_stretched {
    line-height: 140%;
  }
}

h6, .h6 {
  --h-size: 28px;
  line-height: 115%;
}

@include respond-up("large") {
  h1, .h1,
  h2, .h2,
  h4, .h4,
  h5, .h5 {
    line-height: 100%;
  }

  h3, .h3 {
    line-height: 105%;
  }

  h1, .h1 {
    --h-size: calc((200 / 1920) * 100vw);
  }

  h2, .h2 {
    --h-size: calc((140 / 1920) * 100vw);

    &.h2_stretched {
      letter-spacing: calc((39 / 1920) * 100vw);
      --lp-translate-h2: calc((39 / 1920) * 100vw);
      --lp-translate-h2-half: calc((39 / 1920) * 100vw / 2);
    }
  }

  h3, .h3 {
    --h-size: calc((100 / 1920) * 100vw);

    &.h3_stretched {
      letter-spacing: calc((81 / 1920) * 100vw);
      --lp-translate-h3: calc((81 / 1920) * 100vw);
      --lp-translate-h3-half: calc((81 / 1920) * 100vw / 2);
    }
  }

  h4, .h4 {
    --h-size: calc((60 / 1920) * 100vw);
  }

  h5, .h5 {
    --h-size: calc((40 / 1920) * 100vw);

    &.h5_stretched {
      letter-spacing: calc((18 / 1920) * 100vw);
      --lp-translate-h5: calc((18 / 1920) * 100vw);
      --lp-translate-h5-half: calc((18 / 1920) * 100vw / 2);
    }
  }
}

@include respond("s-large") {
  h1, .h1 {
    --h-size: calc((133 / 1280) * 100vw);
  }

  h2, .h2 {
    --h-size: calc((93 / 1280) * 100vw);

    &.h2_stretched {
      letter-spacing: calc((26 / 1280) * 100vw);
      --lp-translate-h2: calc((26 / 1280) * 100vw);
      --lp-translate-h2-half: calc((26 / 1280) * 100vw / 2);
    }
  }

  h3, .h3 {
    --h-size: calc((67 / 1280) * 100vw);

    &.h3_stretched {
      letter-spacing: calc((54 / 1280) * 100vw);
      --lp-translate-h3: calc((54 / 1280) * 100vw);
      --lp-translate-h3-half: calc((54 / 1280) * 100vw / 2);
    }
  }

  h4, .h4 {
    --h-size: calc((40 / 1280) * 100vw);
  }

  h5, .h5 {
    --h-size: calc((32 / 1280) * 100vw);

    &.h5_stretched {
      letter-spacing: calc((14 / 1280) * 100vw);
      --lp-translate-h5: calc((14 / 1280) * 100vw);
      --lp-translate-h5-half: calc((14 / 1280) * 100vw / 2);
    }
  }
}

@include respond("medium") {
  h1, .h1 {
    --h-size: calc((130 / 834) * 100vw);
  }

  h2, .h2 {
    --h-size: calc((100 / 834) * 100vw);

    &.h2_stretched {
      letter-spacing: calc((25 / 834) * 100vw);
      --lp-translate-h2: calc((25 / 834) * 100vw);
      --lp-translate-h2-half: calc((25 / 834) * 100vw / 2);
    }
  }

  h3, .h3 {
    --h-size: calc((70 / 834) * 100vw);

    &.h3_stretched {
      letter-spacing: calc((44 / 834) * 100vw);
      --lp-translate-h3: calc((44 / 834) * 100vw);
      --lp-translate-h3-half: calc((44 / 834) * 100vw / 2);
    }
  }

  h4, .h4 {
    --h-size: calc((50 / 834) * 100vw);
  }

  h5, .h5 {
    --h-size: calc((36 / 834) * 100vw);

    &.h5_stretched {
      --h-size: calc((28 / 834) * 100vw);
      letter-spacing: calc((12 / 834) * 100vw);
      --lp-translate-h5: calc((12 / 834) * 100vw);
      --lp-translate-h5-half: calc((12 / 834) * 100vw / 2);
    }
  }
}

@include respond("small") {
  h1, .h1 {
    --h-size: calc((80 / 375) * 100vw);
  }

  h2, .h2 {
    --h-size: calc((60 / 375) * 100vw);

    &.h2_stretched {
      letter-spacing: calc((7 / 375) * 100vw);
      --lp-translate-h2: calc((7 / 375) * 100vw);
      --lp-translate-h2-half: calc((7 / 375) * 100vw / 2);
    }
  }

  h3, .h3 {
    --h-size: calc((40 / 375) * 100vw);

    &.h3_stretched {
      letter-spacing: calc((17 / 375) * 100vw);
      --lp-translate-h3: calc((17 / 375) * 100vw);
      --lp-translate-h3-half: calc((17 / 375) * 100vw / 2);
    }
  }

  h4, .h4 {
    --h-size: calc((35 / 375) * 100vw);
  }

  h5, .h5 {
    --h-size: calc((30 / 375) * 100vw);

    &.h5_stretched {
      --h-size: calc((20 / 375) * 100vw);
      letter-spacing: calc((9 / 375) * 100vw);
      --lp-translate-h5: calc((9 / 375) * 100vw);
      --lp-translate-h5-half: calc((9 / 375) * 100vw / 2);
    }
  }

  h6, .h6 {
    --h-size: 26px;
  }
}
