.reviews {
  border-top: 1px solid #fff;

  &__meta {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  &__grid {
    display: grid;
    grid-column-gap: var(--grid-gap);
    grid-row-gap: 0;
  }

  &__title,
  &__heading,
  &__item-date,
  &__item-name,
  &__item-job-position,
  &__item-description,
  &__upload-image-link-title {
    color: #fff;
  }

  &__avatar-wrap {
    flex: 0 0 auto;
    margin-right: var(--grid-gap);
  }

  &__item {
    &.swiper-slide {
      display: flex;
      flex-direction: column;
    }

    -youbkit-touch-callout: none; /* Сафари для iOS */
    -youbkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge и Opera 15+ */
    -moz-user-select: none;    /* Fire Fox */
    -ms-user-select: none;        /* IE 10+ и Edge */
    user-select: none;
  }

  &__item-top {
    display: flex;
  }

  &__top-titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 1 100%;
  }

  &__item-job-position {
    margin-top: 20px;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__upload-image-link {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      display: none;
    }
  }

  &__upload-image-wrap {
    display: block;
  }

  &__upload-image-link-title-wrap {
    background-color: var(--graphit-color);
  }


  &__upload-image-link-title {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: 1px solid #fff;
      transform-origin: left center;
      transition: transform .4s var(--default-transition-function);
      transform: scaleX(1);
    }
  }

  &.need-animation {
    .animation-mask__line span {
      transform: translate3d(0, 110%, 0);
    }
  }
}

@include respond-up('large') {
  .reviews {
    &__bottom-container {
      margin-top: 150px;
    }

    &__heading-wrap {
      margin-top: 59px;
    }

    &__arrows {
      margin-top: 73px;
    }

    &__avatar-wrap {
      width: var(--grid-column);
      height: var(--grid-column);
    }

    &__item {
      padding-right: calc(var(--grid-column) + var(--grid-gap));
    }
  }
}

@include respond-up('s-large') {
  .reviews {
    padding-bottom: 60px;

    &__grid {
      grid-template-columns: repeat(12, 1fr);
    }

    &__title-container {
      grid-column: 1/5;
    }

    &__main-container {
      grid-column: 5/13;
    }

    &__bottom-container {
      grid-column: 1/13;
    }

    &__heading {
      width: var(--grid-column2);
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column4);
      }
    }

    &__item-top {
      margin-top: 75px;
    }

    &__item-description {
      margin-top: 53px;
    }

    &__upload-image-link {
      &:hover {
        .reviews__upload-image-link-title {
          &::before {
            transform: scaleX(0);
            transform-origin: center right;
          }
        }
      }
    }
  }
}

@include respond('s-large') {
  .reviews {
    &__bottom-container {
      margin-top: 100px;
    }

    &__avatar-wrap {
      width: calc(var(--grid-column2) - 49px);
      height: calc(var(--grid-column2) - 49px);
    }

    &__arrows {
      margin-top: 53px;
    }
  }
}

@include respond-down('s-large') {
  .reviews {
    &__heading-wrap {
      margin-top: 43px;
    }

    &__item-job-position {
      margin-top: 11px;
    }
  }
}

@include respond-up('medium') {
  .reviews {
    padding-top: 15px;

    &__upload-images {
      margin-top: 35px;
    }

    &__upload-image-wrap {
      width: 93px;
      height: 55px;
    }

    &__upload-image-link-title-wrap {
      padding: 15px 20px;
    }

    &__original-link {
      margin-top: 25px;
    }

    &__item-job-position {
      width: var(--grid-column2);
    }

    &__pagination-wrap {
      display: none;
    }
  }
}

@include respond('medium') {
  .reviews {
    padding-bottom: 40px;

    &__main-container {
      margin-top: 53px;
    }

    &__bottom-container {
      margin-top: 130px;
    }

    &__heading-wrap {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    &__heading {
      width: var(--grid-column3);
    }

    &__avatar-wrap {
      width: var(--grid-column);
      height: var(--grid-column);
      margin-right: unset;
      margin-bottom: 23px;
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column3);
      }
    }

    &__item-date {
      margin-top: 30px;
    }

    &__item-description {
      margin-top: 8px;
    }

    &__item-top {
      flex-direction: column;
    }

    &__top-titles {
      justify-content: flex-start;
    }
  }
}

@include respond-down('medium') {
  .reviews {
    &__grid {
      grid-template-columns: repeat(6, 1fr);
    }

    &__title-container,
    &__main-container,
    &__bottom-container {
      grid-column: 1/7;
    }

    &__item-top {
      order: 1;
    }

    &__item-date {
      order: 2;
      color: var(--white-color-40);
    }

    &__item-description {
      order: 3;
    }

    &__item-bottom {
      order: 4;
    }
  }
}

@include respond-down('small') {
  .reviews {
    padding-top: 9px;
    padding-bottom: 30px;

    &__main-container {
      margin-top: 36px;
    }

    &__bottom-container {
      margin-top: 70px;
    }

    &__heading-wrap {
      margin-top: 29px;
    }

    &__heading {
      width: var(--grid-column4);
    }

    &__arrows {
      display: none;
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column6);
      }
    }

    &__avatar-wrap {
      width: var(--grid-column2);
      height: var(--grid-column2);
    }

    &__item-date {
      margin-top: 25px;
    }

    &__item-description {
      margin-top: 11px;
    }

    &__upload-images {
      margin-top: 25px;
    }

    &__upload-image-wrap {
      width: 71px;
      height: 42px;
    }

    &__upload-image-link-title-wrap {
      padding: 6px 16px;
      height: 42px;
    }

    &__original-link {
      margin-top: 15px;
    }

    &__item-job-position {
      width: var(--grid-column4);
    }

    &__pagination-wrap {
      position: relative;
      margin-top: 40px;
      --swiper-pagination-color: #fff;
      --swiper-pagination-bullet-inactive-color: var(--white-color-40);
      --swiper-pagination-bullet-inactive-opacity: 1;
      --swiper-pagination-bullet-height: 2px;
      --swiper-pagination-bullet-width: 18px;

      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: -4px;
      }

      .swiper-pagination-bullet {
        border-radius: 0;
        transition: background-color .3s var(--default-transition-function);
      }
    }
  }
}