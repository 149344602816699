:root {
  --modal-bg: var(--primary-color-40);
  --modal-content-radius: 0px;
  --modal-content-shadow: none;
  --modal-content-padding: 30px;
  --modal-content-background: #fff;
  --modal-closer-color: #fff;
  --modal-closer-size: 60px;
  --modal-preloader-size: 30px;
}

.modal {
  &__bg {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    background: var(--t);
    transition: background .4s var(--default-transition-function);

    &._opened {
      background: var(--modal-bg);
    }

    &._closed,
    &._closing {
      background: var(--t);
    }
  }

  &__layout {
    position: relative;
    z-index: 11000;
    cursor: default;
    margin: 0 auto;
    height: 100%;
    display: flex;
    transition-delay: 0s;
    transition: transform .4s var(--teaser-transition-func);
  }

  &__container {
    border-radius: var(--modal-content-radius);
    box-shadow: var(--modal-content-shadow);
    position: relative;
    display: flex;
    background: var(--t);

    &._opened {
      .modal__closer {
        opacity: 1;
      }
    }

    &._closed,
    &._closing {
      .modal__closer {
        opacity: 0;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    height: 100%;
    background: var(--modal-content-background);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      max-width: 100%;
    }
  }

  &__closer {
    position: absolute;
    z-index: 25;
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    width: var(--modal-closer-size);
    height: var(--modal-closer-size);
    transition: opacity .4s var(--default-transition-function);
    opacity: 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      background: #fff;
    }

    &:before {
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }

    &:after {
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }
  }
}

body.modal-loading {
  overflow: hidden;
  //--preloader-size: 100px;

  //пульсирующий кружочек раньше был
  &__loader {
  }

  &__content {
    overflow: scroll;
    height: 100%;
    width: 100%;
  }
}

@include respond-up('s-large') {
  .modal {
    &__layout {
      justify-content: flex-end;
    }

    &__container {
      justify-content: flex-end;

      &._opened {
        .modal__content {
          --modal-content-width: max(640px, calc(var(--grid-column4) + var(--grid-spacer) * 2));
          width: var(--modal-content-width);
        }
      }

      &._closing {
        .modal__content {
          width: 0;
        }
      }
    }

    &__content {
      transition: width .4s var(--default-transition-function);
      width: 0;
    }

    &__closer {
      top: 60px;
      left: -100px;

      &:before,
      &:after {
        transition: transform .4s var(--default-transition-function);
      }

      &:hover {
        &:before {
          transform: translate3d(-50%, -50%, 0) rotate(135deg);
        }

        &:after {
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .modal {
    &__closer {
      &::before,
      &::after {
        width: 80px;
      }
    }
  }
}

@include respond('medium') {
  :root {
    --modal-closer-size: 120px;
  }

  .modal {
    &__closer {
      top: 17px;
      right: 6px;
    }
  }
}

@include respond-down('medium') {
  .modal {
    &__layout {
      align-items: flex-end;
    }

    &__container {
      width: 100%;
      align-items: flex-end;

      &._opened {
        .modal__content {
          --modal-content-height: calc(var(--vh, 1svh) * 100);
          height: var(--modal-content-height);
        }
      }

      &._closing {
        .modal__content {
          height: 0;
        }
      }
    }

    &__content {
      width: 100%;
      height: 0;
      transition: height .4s var(--default-transition-function);
    }

    &__closer {
      &::before,
      &::after {
        background: #000;
      }
    }
  }
}

@include respond-down('small') {
  :root {
    --modal-closer-size: 48px;
  }

  .modal {
    &__closer {
      top: 17px;
      right: 6px;

      &::before,
      &::after {
        width: 30px;
      }
    }
  }
}