.animation-mask {
  &__line {
    display: block;
    overflow: hidden;
    width: max-content;
    white-space: nowrap;

    span {
      transition: transform .7s var(--default-transition-function);
    }

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        span {
          transition-delay: calc(((#{$i} - 1) * 0.1s));
        }
      }
    }
  }
}