.tech-specs {
  &__title,
  &__item {
    color: #fff;
  }

  &__wrap {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__image-wrap {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--grid-gap);
    border-top: 1px solid #fff;

    &:last-child {
      border-bottom: 1px solid #fff;
    }
  }

  &__item-title {
    margin-right: var(--grid-gap);
  }

  &__item-title,
  &__item-value {
    flex: 0 0 calc(50% - var(--grid-gap) / 2);
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.need-animation {
    .animation-mask__line span {
      transform: translate3d(0, 110%, 0);
    }
  }
}

@include respond-up('large') {
  .tech-specs {
    &__text-wrap {
      padding-top: 59px;
    }

    &__list {
      margin-top: 73px;
    }

    &__picture {
      height: calc(var(--grid-column8) * 910 / 1217);
    }
  }
}

@include respond-up('s-large') {
  .tech-specs {
    &__wrap {
      grid-template-columns: repeat(12, 1fr);
    }

    &__text-wrap {
      grid-column: 1/5;
    }

    &__image-wrap {
      grid-column: 5/13;
      height: calc(var(--grid-column8) * (910 / 1217));
    }

    &__title {
      width: var(--grid-column3);
    }
  }
}

@include respond('s-large') {
  .tech-specs {
    &__text-wrap {
      padding-top: 43px;
    }

    &__list {
      margin-top: 53px;
    }

    &__picture {
      height: calc(var(--grid-column8) * 591 / 790);
    }
  }
}

@include respond-up('medium') {
  .tech-specs {
    &__item {
      padding: 13px 0 14px;
    }
  }
}

@include respond('medium') {
  .tech-specs {
    &__text-wrap {
      padding-top: 43px;
    }

    &__title {
      width: var(--grid-column4);
    }

    &__image-wrap {
      margin-top: 50px;
    }

    &__list {
      margin-top: 33px;
    }

    &__picture {
      height: calc(var(--grid-column6) * 579 / 774);
    }
  }
}

@include respond-down('medium') {
  .tech-specs {
    &__wrap {
      grid-template-columns: repeat(6, 1fr);
    }

    &__text-wrap {
      grid-column: 1/7;
    }

    &__image-wrap {
      grid-column: 1/7;
      height: calc(var(--grid-column6) * (579 / 774));
    }
  }
}

@include respond-down('small') {
  .tech-specs {
    &__text-wrap {
      padding-top: 29px;
    }

    &__title {
      width: var(--grid-column6);
    }

    &__image-wrap {
      margin-top: 30px;
    }

    &__list {
      margin-top: 26px;
    }

    &__item {
      padding: 9px 0 8px;
    }
  }
}