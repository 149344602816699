.index-page {

}

@include respond-up('large') {
  .index-page {
    &__open-system {
      margin-top: 200px;
    }

    &__team-slider,
    &__certificates {
      margin-top: 195px;
    }
  }
}

@include respond('s-large') {
  .index-page {
    &__open-system {
      margin-top: 150px;
    }

    &__team-slider,
    &__certificates {
      margin-top: 145px;
    }
  }
}

@include respond-up('medium') {
  .index-page {

  }
}

@include respond('medium') {
  .index-page {
    &__open-system {
      margin-top: 130px;
    }

    &__team-slider {
      margin-top: 125px;
    }

    &__certificates {
      margin-top: 120px;
    }
  }
}

@include respond-down('medium') {
  .index-page {

  }
}

@include respond-down('small') {
  .index-page {
    &__open-system {
      margin-top: 70px;
    }

    &__team-slider {
      margin-top: 65px;
    }

    &__certificates {
      margin-top: 61px;
    }
  }
}