.arrow {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  border: 1px solid var(--primary-color);
  background-color: #fff;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-color: var(--primary-color);
    transform: translate3d(0, 100%, 0);
    transition: transform .3s var(--default-transition-function);
  }

  &__icon {
    display: flex;
    mix-blend-mode: difference;
  }
}

@include respond-up('s-large') {
  .arrow {
    &:hover {
      .arrow__background {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}