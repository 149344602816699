.policy {
  position: relative;
  padding-left: 38px;

  input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);

    appearance: none;
    background-color: transparent;
    margin: 0;

    font: inherit;
    color: var(--primary-color);
    width: 22px;
    height: 22px;
    border: none;
    border-radius: 0;
    display: grid;
    place-content: center;
    transition-property: background, border-color;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);
    cursor: pointer;

    &::before {
      content: "";
      width: 22px;
      height: 22px;
      transition: background-image .3s var(--default-transition-function);
      background-image: url('../images/svg/checkbox-unchecked.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &:checked {
      &::before {
        background-image: url('../images/svg/checkbox-checked.svg');
      }
    }
  }

  label {
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    font-size: 14px;
    color: var(--primary-color-40);
  }

  &__link {
    color: var(--primary-color);
  }

  //&_light {
  //  input[type="checkbox"] {
  //    &::before {
  //      background-image: url('../images/svg/checkbox-unchecked.svg');
  //    }
  //
  //    &:checked {
  //      &::before {
  //        background-image: url('../images/svg/checkbox-checked.svg');
  //      }
  //    }
  //  }
  //
  //  label {
  //    color: var(--white-color-40);
  //  }
  //
  //  .policy {
  //    &__link {
  //      .button__title {
  //        color: #fff;
  //
  //        &::before {
  //          border-color: #fff;
  //        }
  //      }
  //    }
  //  }
  //}
}

@include respond-up('s-large') {
  .policy {
    input[type="checkbox"] {
      &:not(:checked) {
        &:hover {
          &::before {
            background-image: url('../images/svg/checkbox-hovered.svg');
          }
        }
      }
    }

    &__link {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid var(--primary-color);
        transform: scaleX(0);
        transform-origin: center right;
        transition: transform .3s var(--default-transition-function);
      }

      &:hover {
        &::before {
          transform: scaleX(1);
          transform-origin: left center;
        }
      }
    }

    //&_light {
    //  input[type="checkbox"] {
    //    &:not(:checked) {
    //      &:hover {
    //        &::before {
    //          background-image: url('../images/svg/checkbox-hovered.svg');
    //        }
    //      }
    //    }
    //  }
    //}
  }
}

@include respond-down('small') {
  .policy {
    label {
      font-size: 12px;
    }
    //input[type="checkbox"] {
    //  top: 50%;
    //  transform: translate3d(0, -50%, 0);
    //}
  }
}