:root {
  --default-input-height: 52px;
}

$inputs: 'input[type="text"], input[type="search"], input[type="password"], input[type="email"]';

form {
  #{$inputs}, textarea, select {
    &:-webkit-autofill {
      transition: background-color 5000s var(--default-transition-function) 0s;
      -webkit-text-fill-color: var(--primary-color) !important;
    }
  }

  label {
    display: block;
    color: var(--primary-color);
  }

  ul.errors {
    list-style: none;
    color: var(--primary-color);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    font-size: 14px;

    li {
      margin-top: 8px;
      position: relative;
      padding-left: 33px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 23px;
        height: 23px;
        transform: translate3d(0,-50%,0);
        background-size: contain;
        background: url("../images/svg/exclamation-in-circle.svg") no-repeat center;
      }
    }
  }

  label,
  #{$inputs},
  textarea {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    font-size: 17px;
  }

  #{$inputs}, textarea {
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    width: 100%;
    display: block;
    appearance: none;
    background-color: var(--t);
    color: var(--primary-color);
    border: none;
    border-bottom: 2px solid var(--primary-color);
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  textarea {
    position: relative;
    padding: 15px 0;
    box-sizing: border-box;
    resize: none;
  }

  .form-field {
  }

  .default-submit-button {
    display: none;
  }
}

@include respond-up('medium') {
  form {
    #{$inputs} {
      padding: 20px 0 18px;
    }
  }
}

@include respond-down('small') {
  :root {
    --default-input-height: 42px;
  }

  form {
    ul.errors {
      font-size: 12px;
    }

    #{$inputs} {
      padding: 15px 0 13px;
    }

    label,
    #{$inputs},
    textarea {
      font-size: 15px;
    }

    textarea {
      padding: 13px 0 11px;
    }
  }
}