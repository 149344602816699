.recall-form {
  overflow: hidden;
  background-color: #fff;

  &__main {
    position: relative;
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__fields {
    display: flex;
    flex-direction: column;
  }

  &__submit {
    width: 100%;
    margin-top: 40px;
  }

  &__description {
    width: var(--grid-column3);
  }

  .default-submit-button {
    display: none;
  }

  &__success {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;

    .animation-mask__line span {
      transform: translate3d(0, 110%, 0);
      transition-delay: .4s !important;
    }
  }

  &__success-title,
  &__success-subtitle {
    text-align: center;
  }

  &__success-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: var(--grid-column4);
  }

  &__success-subtitle {
    width: var(--grid-column2);
  }

  &.need-animation {
    .animation-mask__line span {
      transform: translate3d(0, 110%, 0);
    }
  }

  &.success {
    .recall-form {
      &__success {
        .animation-mask__line span {
          transform: translate3d(0, 0, 0);
          transition-delay: 0s !important;
        }
      }
    }
  }
}

@include respond-up('large') {
  .recall-form {
    &__main {
      margin-top: 57px;
    }

    &__description {
      margin-top: 66px;
    }
  }
}

@include respond-up('s-large') {
  .recall-form {
    padding: 35px 0 110px;

    &__main {
      grid-template-columns: repeat(12, 1fr);
    }

    &__text-container {
      grid-column: 1/7;
    }

    &__data {
      grid-column: 9/13;
    }

    &__field {
      &:not(:first-child) {
        margin-top: 40px;
      }
    }

    &__success {
      justify-content: flex-start;
      left: unset;
      width: var(--grid-column4);
    }

    &__success-subtitle {
      margin-top: 56px;
    }
  }
}

@include respond('s-large') {
  .recall-form {
    &__main {
      margin-top: 41px;
    }

    &__description {
      margin-top: 46px;
      width: var(--grid-column4);
    }
  }
}

@include respond-up('medium') {
  .recall-form {
    &__policy {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .recall-form {
    padding: 25px 0 100px;

    &__main {
      grid-row-gap: 74px;
      margin-top: 59px;
    }

    &__fields {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__description {
      margin-top: 46px;
    }

    &__field {
      width: var(--grid-column3);
    }

    &__success {
      padding: 80px 0;
    }

    &__success-subtitle {
      margin-top: 36px;
    }
  }
}

@include respond-down('medium') {
  .recall-form {
    &__main {
      grid-template-columns: repeat(6, 1fr);
    }

    &__text-container,
    &__data {
      grid-column: 1/7;
    }
  }
}

@include respond('small') {
  .recall-form {
    padding: 15px 0 61px;

    &__main {
      grid-row-gap: 29px;
      margin-top: 36px;
    }

    &__description {
      margin-top: 31px;
      width: var(--grid-column6);
    }

    &__submit {
      margin-top: 20px;
    }

    &__field {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    &__success {
      padding: 50px 0;
    }

    &__success-subtitle {
      margin-top: 21px;
      width: var(--grid-column4);
    }

    &__policy {
      margin-top: 25px;
    }
  }
}