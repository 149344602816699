.modal-content {
  pointer-events: none;

  &__data {
    overflow: hidden;
  }

  &__data-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0 var(--grid-spacer);
    display: flex;
  }

  &._opened {
    pointer-events: auto;

    .modal-content {
      &__closer {
        opacity: 1;
      }
    }
  }
}

@include respond-up('s-large') {
  .modal-content {
    &__data {
      width: 0;
      transition: width .6s var(--default-transition-function);
    }

    &__data-inner {
      width: calc(var(--grid-column8) + var(--grid-spacer) * 2);
    }

    &._opened {
      .modal-content {
        &__data {
          width: calc(var(--grid-column8) + var(--grid-spacer) * 2);
        }
      }
    }
  }
}

@include respond('medium') {
  .modal-content {
    &_product {
      .modal-content {
        &__closer {
          right: 0;
          height: 80px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .modal-content {
    &__data {
      height: 0;
      transition: height .6s var(--default-transition-function);
    }

    &__data-inner {
      width: 100%;
      height: calc(var(--vh, 1svh) * 100);
    }

    &_product {
      .modal-content {
        &__closer {
          top: 0;
        }
      }
    }

    &._opened {
      .modal-content {
        &__data {
          height: calc(var(--vh, 1svh) * 100);
        }
      }
    }
  }
}

@include respond('small') {
  .modal-content {
    &_product {
      .modal-content {
        &__closer {
          right: 6px;
          height: 48px;
        }
      }
    }
  }
}