.header {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--t);
  transition: background-color, opacity;
  transition-duration: .3s;
  transition-timing-function: var(--default-transition-function);
  padding: 0 var(--grid-spacer);

  &__container {
    position: relative;
    z-index: 1999;
    display: grid;
    grid-gap: var(--grid-gap);
    height: var(--header-height);
  }

  &__hamburger-wrap {
    display: flex;
    grid-column: 1/3;
  }

  &__hamburger {
    padding: 0 20px;
    margin: 0 -20px;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      g {
        transition: fill .3s var(--default-transition-function);
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__recall-modal {
    height: 100%;

    &.button {
      margin-left: 20px;
      padding: 0 20px 6px;
    }
  }

  &__phone {
    display: none;
  }

  &._sticky {
    background-color: #fff;
  }

  &._light {
    &._sticky {
      background-color: var(--primary-color);
    }

    .header {
      &__recall-modal {
        .button__title {
          color: #fff;

          &::before {
            border-color: #fff;
          }
        }
      }
    }

    .hamburger {
      &__line {
        background-color: #fff;
      }

      &__title {
        color: #fff;
      }
    }

    .socials {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  &._hidden {
    opacity: 0;
    pointer-events: none;
  }
}

@include respond-up('s-large') {
  .header {
    &__container {
      grid-template-columns: repeat(12, 1fr);
    }

    &__logo {
      grid-column: 6/8;
    }

    &__info {
      grid-column: 10/13;
    }

    &._light {
      .socials__link {
        &:hover {
          svg {
            path {
              fill: var(--white-color-40);
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .header {
    &__logo {
      svg {
        width: 139px;
        height: 44px;
      }
    }

    &__info {
      margin-right: -20px;
    }
  }
}

@include respond-down('medium') {
  .header {
    &__container {
      grid-template-columns: repeat(6, 1fr);
    }

    &__logo {
      grid-column: 3/5;
    }

    &__info {
      grid-column: 5/7;
    }
  }
}

@include respond-down('small') {
  .header {
    &__logo {
      svg {
        width: 88px;
        height: 28px;
      }
    }

    &__recall-modal.button_underline,
    &__socials.socials {
      display: none;
    }

    &__phone {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0 18px;
      margin-right: -18px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}