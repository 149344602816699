.advantages-slider {
  display: flex;

  &__list {
    width: 100%;
  }

  &__item {
    position: static;
    z-index: 10;
    background-color: var(--primary-color);

    &:last-child {
      .advantages-slider {
        &__slide-main {
          border-bottom: 1px solid #fff;
        }
      }
    }

    &.need-animation {
      .animation-mask__line span {
        transform: translate3d(0, 110%, 0);
      }

      .advantages-slider {
        &__picture {
          opacity: 0;
          transform: scale3d(1.1, 1.1, 1);
        }
      }

      .video-layout {
        opacity: 0;
        transform: scale3d(1.1, 1.1, 1);
      }
    }
  }

  &__position,
  &__slide-name,
  &__title,
  &__description {
    color: #fff;
  }

  &__slide-info {
    display: grid;
    grid-gap: var(--grid-gap);
    border-top: 1px solid #fff;
  }

  &__slide-main {
    display: grid;
    grid-gap: var(--grid-gap);
    overflow: hidden;
  }

  &__text-wrap {
    position: relative;
    overflow: hidden;
    display: flex;
    grid-column: 1/2;

    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    }
  }

  &__text-wrap-inner {
    display: flex;
    flex-direction: column;
  }

  &__picture-wrap {
    display: flex;
    width: 100%;
    grid-column: 1/2;
    overflow: hidden;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    transition: opacity, transform;
    transition-duration: .7s;
    transition-timing-function: var(--default-transition-function);
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-layout {
    transition: opacity, transform;
    transition-duration: .7s;
    transition-timing-function: var(--default-transition-function);
  }
}

@include respond-up('large') {
  .advantages-slider {
    &__item {
      .advantages-slider__picture-wrap {
        height: calc(((840 / 980) * 100vh) - (3 * var(--advantage-slide-top)));
      }

      &:nth-child(1) {
        .advantages-slider__picture-wrap {
          height: calc(((840 / 980) * 100vh) - var(--advantage-slide-top));
        }
      }

      &:nth-child(2) {
        .advantages-slider__picture-wrap {
          height: calc(((840 / 980) * 100vh) - (2 * var(--advantage-slide-top)));
        }
      }

      &:not(:last-child) {
        .advantages-slider__description {
          margin-bottom: 67px;
        }
      }
    }

    &__description {
      margin-top: 67px;
      width: var(--grid-column3);
    }
  }
}

@include respond-up('s-large') {
  .advantages-slider {
    &__slide-info {
      grid-template-columns: var(--grid-column4) 1fr;
    }

    &__slide-main {
      grid-template-columns: var(--grid-column4) 1fr;
      padding-bottom: 40px;
    }

    &__picture-wrap {
      grid-column: 2/3;
      max-height: calc(var(--grid-column8) * (808 / 1217));
    }

    &__text-wrap {
      &::before {
        height: 44px;
      }
    }

    &__text-wrap-inner {
      flex-direction: column;
      padding-top: 44px;
    }

    &__title {
      width: var(--grid-column3);
    }
  }
}

@include respond('s-large') {
  .advantages-slider {
    &__item {
      .advantages-slider__picture-wrap {
        height: calc(((663 / 818) * 100vh) - (3 * var(--advantage-slide-top)));
      }

      &:nth-child(1) {
        .advantages-slider__picture-wrap {
          height: calc(((663 / 818) * 100vh) - var(--advantage-slide-top));
        }
      }

      &:nth-child(2) {
        .advantages-slider__picture-wrap {
          height: calc(((663 / 818) * 100vh) - (2 * var(--advantage-slide-top)));
        }
      }

      &:not(:last-child) {
        .advantages-slider__description {
          margin-bottom: 46px;
        }
      }
    }

    &__description {
      margin-top: 46px;
      width: var(--grid-column4);
    }
  }
}

@include respond-up('medium') {
  .advantages-slider {
    --advantage-slide-top: 51px;

    &__slide-info {
      padding: 15px 0;
    }
  }
}

@include respond('medium') {
  .advantages-slider {
    &__slide-info {
      grid-template-columns: repeat(2, var(--grid-column3));
    }

    &__slide-main {
      padding-bottom: 93px;
    }

    &__picture-wrap {
      height: calc(var(--grid-column6) * (449 / 774));
    }

    &__text-wrap-inner {
      flex-direction: row;
      justify-content: space-between;
    }

    &__title,
    &__description {
      flex: 0 0 var(--grid-column3);
    }
  }
}

@include respond-down('medium') {
  .advantages-slider {
    &__picture-wrap {
      grid-row: 1;
    }

    &__text-wrap {
      grid-row: 2;
      padding-top: var(--grid-gap);

      &::before {
        height: var(--grid-gap);
      }
    }

    &__slide-main {
      grid-row-gap: 0;
      grid-template-columns: var(--grid-column6);
    }
  }
}

@include respond-down('small') {
  .advantages-slider {
    --advantage-slide-top: 38px;

    &__slide-info {
      grid-template-columns: max-content auto;
      grid-gap: 12px;
      padding: 9px 0 10px;
      width: var(--grid-column6);
    }

    &__description {
      margin-top: 21px;
    }

    &__picture-wrap {
      height: calc(var(--grid-column6) * (197 / 338));
    }

    &__slide-main {
      padding-bottom: 55px;
    }

    &__title {
      width: var(--grid-column6);
    }

    &__text-wrap {
      &::before {
        height: 12px;
      }
    }
  }
}