/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  small: (
    from: 0,
    to: 767
  ),
  medium: (
    from: 768,
    to: 1179
  ),
  s-large: (
    from: 1180,
    to: 1440
  ),
  large: (
    from: 1441,
    to: 1920
  ),
  x-large: (
    from: 1921
  )
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: 'Manrope', sans-serif;
  --h-font: "Steelfish", sans-serif;

  --large-min-width: 1180;

  --t: transparent;
  --primary-color: #000000; // black
  --graphit-color: #171717; // graphite
  --grey-color: #F2F2F2; // grey
  --alert-color: #FF6969;

  --primary-color-40: rgba(0, 0, 0, 0.4); // black-40
  --primary-color-30: rgba(0, 0, 0, 0.3); // black-30
  --primary-color-20: rgba(0, 0, 0, 0.2); // black-20
  --primary-color-10: rgba(0, 0, 0, 0.1); // black-10

  --white-color-40: rgba(255, 255, 255, 0.4); // white-40

  --default-transition: all var(--default-timing) var(--default-transition-function);
  --default-timing: .4s;
  --default-transition-function: cubic-bezier(.25, .1, .25, 1);
}
