.button {
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;

  &__title {
    position: relative;
    transition: color .3s var(--default-transition-function);

    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      border-bottom: 2px solid var(--primary-color);
      transition: transform .3s, border-color .3s;
      transition-timing-function: var(--default-transition-function);
    }
  }

  &__icon {
    display: flex;
    transition: transform .3s var(--default-transition-function);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &_black,
  &_white {
    overflow: hidden;
    border: 1px solid var(--primary-color);

    .button {
      &__title {
        position: relative;
        z-index: 10;

        &::before {
          transform: scaleX(0);
          transform-origin: center right;
        }
      }

      &__bg {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: transform .3s var(--default-transition-function);
      }
    }
  }

  &_black {
    background-color: #fff;
    
    .button {
      &__bg {
        background-color: black;
      }

      &__title {
        color: white;

        &::before {
          border-color: white;
        }
      }
    }
  }

  &_white {
    background-color: var(--primary-color);

    .button {
      &__bg {
        background-color: white;
      }

      &__title {
        color: var(--primary-color);

        &::before {
          border-color: var(--primary-color);
        }
      }
    }
  }

  &_underline,
  &_icon-underline {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;

    .button {
      &__title {
        &::before {
          transform-origin: left center;
        }
      }
    }
  }

  &_underline-h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;

    &.button_light {
      .h5_underline {
        color: #fff;

        &::before {
          border-color: #fff;
        }
      }
    }
  }

  &_underline-mini {
    .button {
      &__title {
        &::before {
          transform-origin: left center;
          bottom: 0;
          border-bottom: 1px solid var(--primary-color);
        }
      }
    }
  }

  &_underline,
  &_icon-underline,
  &_underline-mini {
    &.button_light {
      .button {
        &__title {
          color: #fff;

          &::before {
            border-bottom-color: #fff;
          }
        }

        &__icon svg path {
          stroke: #fff;
        }
      }
    }
  }

  &_phone {
    width: 60px;
    height: 60px;

    .button__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: var(--primary-color);
      transition: transform, background-color;
      transition-duration: .3s;
      transition-timing-function: var(--default-transition-function);
    }

    svg {
      transition: transform, background-color;
      transition-duration: .3s;
      transition-timing-function: var(--default-transition-function);
    }

    &._black {
      .button__inner {
        background-color: var(--primary-color);
      }

      svg path {
        fill: #fff;
      }
    }

    &._white {
      .button__inner {
        background-color: #fff;
      }

      svg path {
        fill: var(--primary-color);
      }
    }
  }
}

@include respond-up('s-large') {
  .button {
    &_black {
      &:hover {
        .button {
          &__bg {
            transform: translate3d(0, -100%, 0);
          }

          &__title {
            color: var(--primary-color);

            &::before {
              transform: scaleX(1);
              transform-origin: left center;
              border-color: var(--primary-color);
            }
          }
        }
      }
    }

    &_white {
      &:hover {
        .button {
          &__bg {
            transform: translate3d(0, -100%, 0);
          }

          &__title {
            color: #fff;

            &::before {
              transform: scaleX(1);
              transform-origin: left center;
              border-color: #fff;
            }
          }
        }
      }
    }

    &_underline,
    &_icon-underline {
      &:hover {
        .button {
          &__icon {
            transform: rotate(-90deg);
          }

          &__title {
            &::before {
              transform: scaleX(0);
              transform-origin: center right;
            }
          }
        }
      }
    }

    &_underline-h5 {
      &:hover {
        .h5_underline {
          &::before {
            transform: scaleX(0);
            transform-origin: center right;
          }
        }
      }
    }

    &_underline-mini {
      &:hover {
        .button {
          &__title {
            &::before {
              transform: scaleX(0);
              transform-origin: center right;
            }
          }
        }
      }
    }

    &_phone {
      &:hover {
        svg {
          transform: scale(1.25);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .button {
    &_black,
    &_white {
      height: 78px;
      padding: 22px;
    }

    &_underline,
    &_icon-underline {
      .button {
        &__icon {
          width: 22px;
          height: 22px;
        }
      }
    }

    &_icon-underline {
      .button {
        &__title {
          margin-left: 10px;
        }
      }
    }
  }
}

@include respond('small') {
  .button {
    &_black,
    &_white {
      height: 58px;
      padding: 13px;
    }

    &_underline,
    &_icon-underline {
      .button {
        &__icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    &_icon-underline {
      .button {
        &__title {
          margin-left: 8px;
        }
      }
    }
  }
}