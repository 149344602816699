.socials {
  display: flex;
  align-items: center;
  margin: 0 -5px;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;

    svg {
      path {
        transition: fill .3s var(--default-transition-function);
      }
    }
  }

  &_light {
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

@include respond-up('s-large') {
  .socials {
    &__link {
      &:hover {
        svg {
          path {
            fill: var(--primary-color-40);
          }
        }
      }
    }

    &_light {
      .socials__link {
        &:hover {
          svg {
            path {
              fill: var(--white-color-40);
            }
          }
        }
      }
    }
  }
}