.modal-content-recall-form {
  display: flex;

  &__form-container {
    position: relative;
  }

  &__submit {
    width: 100%;
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
  }

  &__success-title,
  &__success-subtitle {
    text-align: center;
  }

  &.success {
    .modal-content-recall-form__success {
    }
  }
}

@include respond-up('s-large') {
  .modal-content-recall-form {
    justify-content: space-between;

    &__text-container,
    &__form-container {
      flex: 0 0 var(--grid-column4);
    }

    &__field {
      &_phone {
        margin-top: 53px;
      }
    }
  }
}

@include respond-up('medium') {
  .modal-content-recall-form {
    &__title,
    &__subtitle {
      width: var(--grid-column3);
    }

    &__subtitle {
      margin-top: 37px;
    }

    &__submit {
      margin-top: 40px;
    }

    &__policy {
      margin-top: 30px;
    }

    &__success-subtitle {
      margin-top: 37px;
    }

    &__success-title {
      width: calc(var(--grid-column4) - 152px);
    }

    &__success-subtitle {
      width: var(--grid-column2);
    }
  }
}

@include respond('medium') {
  .modal-content-recall-form {
    &__form-container {
      margin-top: 75px;
    }

    &__fields {
      display: flex;
      justify-content: space-between;
    }

    &__field {
      flex: 0 0 var(--grid-column3);
    }
  }
}

@include respond-down('medium') {
  .modal-content-recall-form {
    flex-direction: column;
  }
}

@include respond-down('small') {
  .modal-content-recall-form {
    &__form-container {
      margin-top: 40px;
    }

    &__title,
    &__subtitle {
      width: var(--grid-column5);
    }

    &__subtitle {
      margin-top: 21px;
    }

    &__field {
      &_phone {
        margin-top: 30px;
      }
    }

    &__submit {
      margin-top: 20px;
    }

    &__policy {
      margin-top: 25px;
    }

    &__success {
      justify-content: center;
    }

    &__success-subtitle {
      margin-top: 21px;
    }

    &__success-title,
    &__success-subtitle {
      width: var(--grid-column4);
    }
  }
}