@font-face {
    font-family: 'Steelfish';
    src: url('Steelfish-Bold.eot');
    src: local('Steelfish-Bold'), local('Steelfish-Bold'),
    url('Steelfish-Bold.eot?#iefix') format('embedded-opentype'),
    url('Steelfish-Bold.woff2') format('woff2'),
    url('Steelfish-Bold.woff') format('woff'),
    url('Steelfish-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Steelfish';
    src: url('Steelfish-Regular.eot');
    src: local('Steelfish-Regular'),
    url('Steelfish-Regular.eot?#iefix') format('embedded-opentype'),
    url('Steelfish-Regular.woff2') format('woff2'),
    url('Steelfish-Regular.woff') format('woff'),
    url('Steelfish-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}